import React, { useState, useEffect  } from 'react';
import { MenuComponent, } from '@syncfusion/ej2-react-navigations';
import { Link } from 'react-router-dom';
import Axios from 'axios';
const menuFields = {
    itemId: 'd',
    parentId: 'pid',
    text: 'text'
  };
export default class Footermenu1 extends React.Component {
  constructor(props) {
    super(props);
    //this.getID = sessionStorage.getItem('pageid');
    //this.Lang = sessionStorage.getItem('lang');
    //this.storageChanged = this.storageChanged.bind(this);
  }
    state = {
        mainmenus: []
      }
      componentDidMount() {
        Axios.get(`https://alizzapi.alizzislamic.com/api/navigation/render/footer-menu-3?type=TREE&locale=en`)
        //Axios.get('./menu.json')
          .then(res => {
            const mainmenus = res.data;
            this.setState({ mainmenus });
            
          })
          
      }
      handleChangeDash (event)
        { 
          var attribute = event.target.attributes.getNamedItem('data-tag').value;    
          //alert(attribute);
          sessionStorage.setItem('pageid', attribute);
        }
        onLinkClick(e) {
          //e.preventDefault();
          // further processing happens here
          var attribute = e.target.attributes.getNamedItem('data-tag').value;  
          sessionStorage.setItem('pageid', attribute);
//console.log(attribute);
//window.location.reload();
       }
      render() {
        //console.log(this.state.mainmenus);
    return(
        <>{           
          this.state.mainmenus
            .map( (mainmenu, index) =>
            {	
              return(<><h4 key={mainmenu.id}><a  onClick={this.onLinkClick} className="link-light liststy" data-tag={mainmenu.id} href={mainmenu.path}>{mainmenu.title}</a>{/*<Link to={mainmenu.path} className="link-light liststy" data-tag={mainmenu.id} onClick={this.onLinkClick} >{mainmenu.title}</Link>*/}</h4>{(() => {
                  if (mainmenu.items[0]) {
                    return (<><ul className="list-group pb-3 frstm">{           
                              mainmenu.items
                              .map( (submenu, index) =>
                              {
                                return(<li  key={submenu.id} className="list-group-item liststy"><a  onClick={this.onLinkClick} className="link-light" data-tag={submenu.id} href={submenu.path}>{submenu.title}</a>
{/*<Link to={submenu.path} className="secondLevel" data-tag={submenu.id} onClick={this.onLinkClick} >{submenu.title}</Link>*/}
{(() => {
								 //console.log(submenu);
                  if (submenu.items[0]) {
							return (<ul className="list-group pb-3">{           
                              submenu.items
                              .map( (ssubmenu, index) =>
                              {
                                return(<li key={ssubmenu.id} className="list-group-item liststy"><a className="link-light" onClick={this.onLinkClick} href={ssubmenu.path}  data-tag={ssubmenu.id}>{ssubmenu.title}</a> </li>)
							} )}
                                    </ul>)
							   } 
                })()}

</li>)
                              }
                              )
                            }</ul></>)} 
                })()}</>)
          }
            )
        }
</>)
      }
    }



    