import React, { useState, useEffect,useRef  } from 'react';
import { graphql } from "@apollo/client/react/hoc";
import { InMemoryCache,ApolloClient ,useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
//import ReactCardSlider from 'react-card-slider-component';
import Slider, { slickGoTo } from "react-slick";
import Customerinquiryform from './Customerinquiryformar';
import parse from 'html-react-parser';
import $ from 'jquery';
import jQuery from 'jquery';
import Axios from 'axios';
window.$ = window.jQuery = require('jquery')
/*const script = document.createElement("script");
    script.src = "/js/card-slider-min.js";
    script.async = true;
    document.body.appendChild(script);*/
	
class Financials extends  React.Component  {
  constructor(props) {
    super(props);
    //this.getID = sessionStorage.getItem('pageid');
    //this.Lang = sessionStorage.getItem('lang');
//alert(this.Lang);
    //this.storageChanged = this.storageChanged.bind(this);
  }

state = {
        sliderdet: [],
      }
      componentDidMount() {
	
	//var lng= sessionStorage.getItem('lang');
	Axios.get("https://alizzapi.alizzislamic.com/api/customerexperienceinquiry?locale=ar&populate[0]=BannerImage,TabContent.FeaturedImage")
        //Axios.get('./menu.json')
          .then(res => {
			const pagetitle = res.data.data.attributes.Title;
			const introcontent = res.data.data.attributes.Introcontent;
			const tabheading = res.data.data.attributes.TabHeading;
            //const sliderdet = res.data.data.attributes.BannerImage.data.attributes.url;
			const tabcontentdet = res.data.data.attributes.TabContent;
			//this.setState({ pagetitle,introcontent,tabheading,sliderdet,tabcontentdet });
		this.setState({ pagetitle,introcontent,tabheading,tabcontentdet });
            //console.log(sliderdet);
          });
//alert(4);

      }
 handleClickEvent(e, value) {
       // var clck = this.state.context.find('#dd'+value);
		//console.log("clicked id:"+clck);
		//alert(e);
		//$("#dslide").current.slickGoTo(e);
		//$('#dslide').slick('slickGoTo', e); 
    } 
      render() {
   // console.log(this.state.sliderdet);
	//var asr=[];
	//asr=this.state.sliderdet;
	var qrtldt=[];
	qrtldt=this.state.tabcontentdet;
	
	var intrc=this.state.introcontent;
var actnm='s';
	var pth='https://alizzapi.alizzislamic.com';
	document.title = this.state.pagetitle;
	var settings = {
    dots: true,
	fade: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
	autoplay: true,
  autoplaySpeed: 4000,
  arrows: false
  };
  //console.log(qrtldt);
    return (
	
	<>
	
	 <div className="bannerarea pb-4 pt-4">
    <div id="homebanner" className="bannerslider">

          <div className="container">
		  {/*<Slider {...settings}>
            <div className="row">
			{(() => {
        if(asr!=null){
              return (<><div className="col-md-6">
				<img src={pth + asr} alt={this.state.pagetitle} className=" img-fluid"/>
              </div></>); 
        }
    })()}
              <div className="col-md-6 bnrcntbox">
                <div className="box">
                  <h3>{this.state.pagetitle}</h3>
                </div>
              </div>
            </div>
			</Slider>*/}
			<div className="row">
			<div className="col-md-12 bnrcntbox hnm">
                  <h3>{this.state.pagetitle}</h3>
              </div>
			</div>
			
			
          </div>

    </div>
	</div>
	
	
	<section class="section contact-us">
    <div class="container contactus-container">
	<div className="introtxtdiv">
      {intrc}
	  </div>
      <div class="row">
        <div class="col-md-12">
			<Customerinquiryform/>
          {/*<form>
            <div class="row d-flex pb-4">
              <div class="col-md-3">
                Type</div>
              <div class="col-md-9">
                <select class="form-select" name="Type">
                  <option selected="selected" value="--Select--">--Select--</option>
                  <option value="Ad Dakhiliyah">Inquiry</option>
                  <option value="Ad Dhahirah">Complaint</option>
                  <option value="Al Batinah North">Suggestion</option>
                  <option value="Al Batinah South"> Feedback </option>
                </select>
              </div>
            </div>

            <div class="row d-flex pb-4">
              <div class="col-md-3">
                Query*
              </div>
              <div class="col-md-9">
                <textarea class="form-control" id="comment" name="Query" placeholder="Comment goes here"></textarea>
              </div>
            </div>

            <div class="row d-flex pb-4">
              <div class="col-md-3">
                Name*
              </div>
              <div class="col-md-9">
                <input type="text" class="form-control" placeholder="Name" name="Name"/>
              </div>
            </div>
            <div class="row d-flex pb-4">
              <div class="col-md-3">
                Email*
              </div>
              <div class="col-md-9">
                <input type="text" class="form-control" placeholder="Email" name="Email"/>
              </div>
            </div>
            <div class="row d-flex pb-4">
              <div class="col-md-3">
                Phone Number*
              </div>
              <div class="col-md-9">
                <input type="text" class="form-control" placeholder="Phone Number" name="Phone"/>
              </div>
            </div>

            <div class="row d-flex pb-4">
              <div class="col-md-12 text-center">
                <button type="button" class="btn btn-primary active">Submit</button>&nbsp;
                <button type="button" class="btn btn-primary">Reset</button>
              </div>
            </div>
          </form>*/}
        </div>
      </div>
    </div>


    <div class="container pb-5">
      <div class="row">
        <div class="col-md-12 pb-3">
          <h2>{this.state.tabheading}</h2>
        </div>

        <div class="col-md-12 tnpnlinr">
		<div id="accordion">
	  {qrtldt?.map((post, index) => {
		  var ff="#ff";
		  var fff="ff";
			   return (
				<>
        <div class="card">
          <div class="card-header">
            <a class="btn collaptxt" data-bs-toggle="collapse" href={ff+index}>
              {post.Title}
            </a>
          </div>
          <div id={fff+index} className={`collapse ${index==0 ? "show" : ""}`} data-bs-parent="#accordion">
            <div class="card-body">
			  {(() => {
        if(post.FeaturedImage.data!=null){
              return (<><div className="col-md-6">
				<img src={pth + post.FeaturedImage.data.data.attributes.url} alt={post.Title} className="imgexebio img-fluid"/>
              </div></>); 
        }
 })()}
              {parse(post.Content)}
            </div>
          </div>
        </div>
		</>
			   )
			})}
		</div>
		

        </div>
      </div>

    </div>
  </section>

	  </>
	  
    );
  }
    }


export default Financials;
    