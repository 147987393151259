import React, { useEffect, useState } from "react";
import jQuery from 'jquery';
//import $ from 'jquery';
import Footermenufirst from "./Footermenu1";
import Footermenusecond from "./Footermenu2";
import Footermenuthird from "./Footermenu3";
import Footermenufirstar from "./Footermenu1ar";
import Footermenusecondar from "./Footermenu2ar";
import Footermenuthirdar from "./Footermenu3ar";
import Copyrightmenu from "./Copyrightmenu";
import Copyrightmenuar from "./Copyrightmenuar";
//import CookieConsent, { Cookies } from "react-cookie-consent";


//import axios from "axios";
//import { Nav, NavLink, NavMenu }     from "./HeaderElements";
//import Topmenu from "./TopMenu";
/*const locales = {
  en: { 
  sitemap:'Sitemap',
  downloadmobileapp:'Download Mobile Banking App',
  alizzislamicbank: 'Alizz Islamic Bank'
  },
  ar: { 
  sitemap:'خريطة الموقع',
  downloadmobileapp:'احصل على تطبيق العز للخدمات المصرفية عبر الهاتف النقال',
  alizzislamicbank: 'بنك العز الاسلامي'
  }
};
var lgng= sessionStorage.getItem('lang');
const langcontent=locales[lgng];*/

window.$ = window.jQuery = jQuery;
const Footer = () => {
  const [topmenus, getTopmenus, lang, setLang] = useState('');
	const queryParameters = new URLSearchParams(window.location.search);
	const lngg=window.location.pathname.split('/');
  //alert(getLang);
  /*const getLang = sessionStorage.getItem('lang');
  if (getLang == 'ar') {

  } else {

  }
*/
 
  useEffect(() => {
   
  }, []);

  /*const getAllTopmenus=()=>{
  axios.get(`${url}past`).then((response)=>{
      const allTopmenus=response.data.topmenus.allTopmenus;
      getTopmenus(allTopmenus);
  }).catch(error=>console.error(`Error:${error}`));
  }*/


  return (<>
    <section className="section footsect">
      <div className="container  pb-4">
        <div className="row footer-menu">
          <div className="col-md-12">
            <button data-bs-toggle="collapse" data-bs-target="#footermenu" className="footer-menu-btn">
              <img src="/images/down-arrow.png" />
              {(() => {
                if(lngg[1]=='ar'){
                  return (<><span>خريطة الموقع</span></>);
                } else {
                  return (<><span>Sitemap</span></>);
                }
              })()}
            </button>
            <div id="footermenu" className="collapse">
              <div className="d-inline-flex mx-4 mb-4 row">
                <div className="col-md-4">
					{(() => {
                if(lngg[1]=='ar'){
                  return (<><Footermenufirstar /></>);
                } else {
                  return (<><Footermenufirst /></>);
                }
              })()}
                </div>
                <div className="col-md-4">
					{(() => {
                if(lngg[1]=='ar'){
                  return (<><Footermenusecondar /></>);
                } else {
                  return (<><Footermenusecond /></>);
                }
              })()}
                </div>
                <div className="col-md-4">
					{(() => {
                if(lngg[1]=='ar'){
                  return (<><Footermenuthirdar /></>);
                } else {
                  return (<><Footermenuthird /></>);
                }
              })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section copyrightsect">
      <div className="container  pb-5">
        <div className="row">
          <div className="col-md-2 rounded-nw5">
            <a href="https://www.facebook.com/alizzislamicbank" target="_blank"><img src="/images/facebook.svg" className="imglogo4" /></a>
            <a href="https://twitter.com/@alizz_islamic" target="_blank"><img src="/images/twitter.svg" className="imglogo4" /></a>
            <a href="https://www.instagram.com/alizz_islamic/" target="_blank"><img src="/images/instagram.svg" className="imglogo4" /></a>
            <a href="https://www.linkedin.com/company/3177741?trk=tyah&trkInfo=tas%3Aalizz" target="_blank"><img src="/images/linkedin.svg" className="imglogo4" /></a>
            <a href="https://www.youtube.com/channel/UCnPtIVVCvrT7_QEE_V_hyEA" target="_blank"><img src="/images/youtube.svg" className="imglogo4" /></a>

          </div>
          <div className="col-md-6 rounded-nw5">
            <h6 className="fn-sty3">
              {(() => {
                if(lngg[1]=='ar'){
                  return (<><span></span></>);
                } else {
                  return (<><span></span></>);
                }
              })()}
            </h6>
            <a href="https://play.google.com/store/apps/details?id=com.alizzbank.android.mobile" target="_blank"><img src="/images/playstore.svg" className="imglogo4" /></a>
            <a href="https://appsto.re/om/iBCPQ.i" target="_blank"><img src="/images/ios.svg" className="imglogo4 apl" /></a>
            <a href="https://appgallery.huawei.com/#/app/C103087723" target="_blank"><img src="/images/huwai.svg" className="imglogo4" /></a>

          </div>
          <div className="col-md-4 fn-sty4">
            <h6 className="pt-2"> 
				{(() => {
                if(lngg[1]=='ar'){
                  return (<><Copyrightmenuar /></>);
                } else {
                  return (<><Copyrightmenu /></>);
                }
              })()}
				 <p>&copy;
              {(() => {
                if(lngg[1]=='ar'){
                  return (<><span>بنك العز الاسلامي</span></>);
                } else {
                  return (<><span>Alizz Islamic Bank</span></>);
                }
              })()}
            </p></h6>
          </div>
        </div>
      </div>
    </section>




    {(() => {
      /*return (<> <CookieConsent
     location="bottom"
     buttonText="Got It!"
     cookieName="myAwesomeCookieName2"
     style={{ background: "#d3e0f0",color:"#171461" }}
     buttonStyle={{ color: "#fff", fontSize: "13px",background: "#2b5897" }}
     expires={150}
   >
     This website uses cookies to ensure you get the best experience on our website. Learn more
   </CookieConsent></>);*/
    })()}


  </>
  );
};

export default Footer;