import React, { useState, useEffect  } from 'react';
import { graphql } from "@apollo/client/react/hoc";
import { InMemoryCache,ApolloClient ,useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import $ from 'jquery';
import jQuery from 'jquery';
import Axios from 'axios';
window.$ = window.jQuery = require('jquery')
/*const script = document.createElement("script");
    script.src = "/js/card-slider-min.js";
    script.async = true;
    document.body.appendChild(script);*/
/*const locales = {
  en: { 
  getintouch:'Get in touch',
  },
  ar: { 
  getintouch:'تواصل معنا',
  }
};
var lgng= sessionStorage.getItem('lang');
const langcontent=locales[lgng];*/
class Homegetintouch extends  React.Component  {
  constructor(props) {
    super(props);
    //this.getID = sessionStorage.getItem('pageid');
    //this.Lang = sessionStorage.getItem('lang');
//alert(this.Lang);
    //this.storageChanged = this.storageChanged.bind(this);
  }

state = {
        sliderdet: []
      }

      componentDidMount() {
	
	//var lng= sessionStorage.getItem('lang');
	Axios.get("https://alizzapi.alizzislamic.com/api/home?locale=en&populate[0]=HomeGetinTouch.Icon")
        //Axios.get('./menu.json')
          .then(res => {
            const sliderdet = res.data.data.attributes.HomeGetinTouch;
			this.setState({ sliderdet });
            //console.log(sliderdet);
          });
//alert(4);

      }
 handleClickEvent(e, value) {
       // var clck = this.state.context.find('#dd'+value);
		//console.log("clicked id:"+clck);
    } 
      render() {
    //console.log(this.state.sliderdet);
	var asr=[];
	asr=this.state.sliderdet;
	
var actnm='s';
	var pth='https://alizzapi.alizzislamic.com/';
    return (
	
	<>
	<section className="section gettouchsect">
    <div className="container pb-5">
      <div className="row">
        <div className="col-md-12">
          <h3 className="mb-3">
		  <span>Get in touch</span>
		  </h3>
        </div>
      </div>

      <div className="row">
	  {asr.map((post, index) => {
			   return (
				<>
        <div className="col-md-3 rounded-nw4">
		<a href={post.Link} target={post.Target}>
		  <img src={pth + post.Icon.data.attributes.url} alt={post.Title} className="img-fluid imglogo3"/>
          <h5 className="fn-sty3">{post.Title} <br/><font>{parse(post.Details)}</font></h5>
		  </a>
        </div>
		</>
			   )
			})}
      </div>
    </div>
  </section>

	  </>
	  
    );
  }
    }


export default Homegetintouch;
    