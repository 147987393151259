import React, { useState, useEffect  } from 'react';
import { graphql } from "@apollo/client/react/hoc";
import { InMemoryCache,ApolloClient ,useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
//import ReactCardSlider from 'react-card-slider-component';
import parse from 'html-react-parser';
import $ from 'jquery';
import jQuery from 'jquery';
import Axios from 'axios';
window.$ = window.jQuery = require('jquery')
/*const cache = new InMemoryCache({
  typePolicies: {
    __typename: {
      fields: {
        Account: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
  },
});*/
export const MAINTABS = gql`
{
    account {
      data {
id
        attributes {
            Slider {
			id
              Title
              Subtitle
              ShortText
              LinkText
              Link
              Icon{
               data{
                id
                attributes{
                  name
				  url
                  width
                height
                size
                formats
                createdAt
                previewUrl
                }
              }
              }
              }
              }
            }
          }
          }
`

/*const script = document.createElement("script");
    script.src = "/js/card-slider-min.js";
    script.async = true;
    document.body.appendChild(script);*/
	/*const cscript = document.createElement("script");
    cscript.src = "/js/cardslider.js";
    cscript.async = true;
    document.body.appendChild(cscript);*/
	
class Accountslidercontent extends  React.Component  {
  constructor(props) {
    super(props);
    //this.getID = sessionStorage.getItem('pageid');
    //this.Lang = sessionStorage.getItem('lang');
//alert(this.Lang);
    //this.storageChanged = this.storageChanged.bind(this);
  }

displaySlider = () => {
    const sdata = this.props.data;
	
	var pth='https://alizzapi.alizzislamic.com/';
    if (sdata) {
      return <div>Loading Data ...</div>;
    } else {
		//console.log(sdata);
      return sdata.account.data.attributes.Slider.map((main) => {
        return (
		<>
		<div key={main.id} className="slide">
                <div className="sldbox" vl="alizzone">
                  <h2>{main.Title}</h2>
				  <img src={pth + main.Icon.data.attributes.url} alt={main.Title} className="sldimg"/>
                  <div className="sldcnt">
                    <h5>{main.Subtitle}</h5>
                    <p>{main.ShortText}</p>
                  </div>
                  <a type="submit" className="btn btn-primary">Select</a>
                </div>
              </div>
			  </>
		);
      });
    }
  };
state = {
        sliderdet: []
      }
      componentDidMount() {

	 
	Axios.get(`https://alizzapi.alizzislamic.com/api/account?locale=en&populate=BankDetails.Icon`)
        //Axios.get('./menu.json')
          .then(res => {
            const sliderdet = res.data.data.attributes.BankDetails;
			this.setState({ sliderdet });
            //console.log(sliderdet);
          });
//alert(4);

      
        
      }
 handleClickEvent(e, value) {
        var myDomEl = this.state.context.find('#dd'+value);
    } 
      render() {
    //console.log(this.props);
	//console.log(this.state.sliderdet);
	var pth='https://alizzapi.alizzislamic.com/';
    return (
	<>
			{/*this.displaySlider()*/}
	  {           
          this.state.sliderdet.map( (prdtl, index) =>
            {	
			var dd="dd";
return(<>
<div key={prdtl.id} id={dd+index}  className={`expntcnt ${index==0 ? "active" : ""}`}>
<h2>{prdtl.Title}</h2>
</div>
</>)
          })
        }


	  </>
	  
    );
  }
    }


export default Accountslidercontent;
    