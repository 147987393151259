import React, { useState, useEffect,useRef  } from 'react';
import { graphql } from "@apollo/client/react/hoc";
import { InMemoryCache,ApolloClient ,useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
//import ReactCardSlider from 'react-card-slider-component';
import Slider, { slickGoTo } from "react-slick";
import parse from 'html-react-parser';
import $ from 'jquery';
import jQuery from 'jquery';
import Axios from 'axios';
window.$ = window.jQuery = require('jquery')
export const MAINTABS = gql`
{
    account {
      data {
id
        attributes {
            Slider {
			id
              Title
              Subtitle
              ShortText
              LinkText
              Link
              Icon{
               data{
                id
                attributes{
                  name
				  url
                  width
                height
                size
                formats
                createdAt
                previewUrl
                }
              }
              }
              }
              }
            }
          }
          }
`

/*const script = document.createElement("script");
    script.src = "/js/card-slider-min.js";
    script.async = true;
    document.body.appendChild(script);*/
	
class Hometabslider extends  React.Component  {
  constructor(props) {
    super(props);
    //this.getID = sessionStorage.getItem('pageid');
    //this.Lang = sessionStorage.getItem('lang');
//alert(this.Lang);
    //this.storageChanged = this.storageChanged.bind(this);
  }

state = {
        sliderdet: [],
		getintouchdet:[]
      }

      componentDidMount() {
	
	//var lng= sessionStorage.getItem('lang');
	Axios.get("https://alizzapi.alizzislamic.com/api/home?locale=ar&populate[0]=HomeTabSlider.FeaturedImage,HomeGetinTouch.Icon")
        //Axios.get('./menu.json')
          .then(res => {
            const sliderdet = res.data.data.attributes.HomeTabSlider;
			const getintouchdet = res.data.data.attributes.HomeGetinTouch;
			this.setState({ sliderdet,getintouchdet });
            //console.log(sliderdet);
          });
//alert(4);

      }
 handleClickEvent(e, value) {
       // var clck = this.state.context.find('#dd'+value);
		//console.log("clicked id:"+clck);
		//alert(e);
		//$("#dslide").current.slickGoTo(e);
		//$('#dslide').slick('slickGoTo', e); 
    } 
      render() {
    //console.log(this.state.sliderdet);
	var asr=[];
	asr=this.state.sliderdet;
		  
	var vasr=[];
	vasr=this.state.getintouchdet;
	
var actnm='s';
	var pth='https://alizzapi.alizzislamic.com/';
	var settings = {
    dots: true,
	fade: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
	autoplay: true,
	beforeChange: (oldIndex, newIndex) => {
    setTimeout(() => {
      const sample = document.querySelectorAll(".nv");

      for (let i = 0; i < sample.length; i++) {
        sample[i].classList.remove("active");
        //console.log("beforeChange", oldIndex, newIndex);
      }
      
    });
  },
  afterChange: (index) => {
    setTimeout(() => {
      const sample = document.querySelector('#s'+index);
      sample.classList.add("active");
      //console.log("afterChange", index);
    });
  },
  autoplaySpeed: 6000,
  arrows: false,

  };
    return (
	
	<>

<section className="section ftabsect">
    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col-md-12">
          <div className="nav nav-tabs slidetab" id="nav-tab" role="tablist">
			{asr.map((post, index) => {
			   return (
				<>
				 <button key={post.id} className={`nav-link nv ${index==0 ? "active" : ""}`} vl={index} id={actnm+index} onClick={e => this.slider.slickGoTo(index)}>{post.Title}</button>
				</>
			   )
			})}
          </div>
        </div>
      </div>

      <div id="dslide" className="dslide">
        <ul className="slides">
		<Slider {...settings} ref={slider => (this.slider = slider)}>
		{asr.map((post, index) => {
			   return (
				<>
          <li key={post.id}>
            <div className="row">
              <div className="col-md-5 tbcntlft">
                <h5>{post.Title}</h5>
                <h3>{post.MainTitle}</h3>
                <span>
                  {parse(post.Content)}
                </span>
                {(() => {
        if(post.LinkText!=null){
            return (<><a href={post.Link} className="btn btn-primary sty1 mx-2">{post.LinkText}</a></>); 
        }
    })()}

              </div>
              <div className="col-md-7 text-center slideimg">
				<img src={pth + post.FeaturedImage.data.attributes.url} alt={post.Title} className="img-fluid"/>
              </div>
            </div>
          </li>
		  </>
			   )
			})}
		  </Slider>
        </ul>
      </div>


    </div>
  </section>

<section className="section gettouchsect">
    <div className="container pb-5">
      <div className="row">
        <div className="col-md-12">
          <h3 className="mb-3">
		  <span>تواصل معنا</span>
		  </h3>
        </div>
      </div>

      <div className="row">
	  {vasr.map((post, index) => {
			   return (
				<>
        <div className="col-md-3 rounded-nw4">
		<a href={post.Link} target={post.Target}>
		  <img src={pth + post.Icon.data.attributes.url} alt={post.Title} className="img-fluid imglogo3"/>
          <h5 className="fn-sty3">{post.Title} <br/><font>{parse(post.Details)}</font></h5>
		  </a>
        </div>
		</>
			   )
			})}
      </div>
    </div>
  </section>

	  </>
	  
    );
  }
    }


export default Hometabslider;
    