import React, { useState, useEffect,useRef  } from 'react';
import { graphql } from "@apollo/client/react/hoc";
import { InMemoryCache,ApolloClient ,useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
//import ReactCardSlider from 'react-card-slider-component';
import Slider, { slickGoTo } from "react-slick";
import Customerinquiryform from './Customerinquiryform';
import parse from 'html-react-parser';
import $ from 'jquery';
import jQuery from 'jquery';
import Axios from 'axios';
window.$ = window.jQuery = require('jquery')
/*const script = document.createElement("script");
    script.src = "/js/card-slider-min.js";
    script.async = true;
    document.body.appendChild(script);*/
	
class Careerpage extends  React.Component  {
  constructor(props) {
    super(props);
    //this.getID = sessionStorage.getItem('pageid');
    //this.Lang = sessionStorage.getItem('lang');
//alert(this.Lang);
    //this.storageChanged = this.storageChanged.bind(this);
  }

state = {
        sliderdet: [],
      }
      componentDidMount() {
	
	//var lng= sessionStorage.getItem('lang');
	Axios.get("https://alizzapi.alizzislamic.com/api/alizzcareer?locale=en&populate[0]=PrivacyContent")
        //Axios.get('./menu.json')
          .then(res => {
			const pagetitle = res.data.data.attributes.Title;
			const introcontent = res.data.data.attributes.Content;
			const privacyheading = res.data.data.attributes.PrivacyHeading;
            const jobpageheading = res.data.data.attributes.JobPageHeading;
			const linktext = res.data.data.attributes.LinkText;
			const privacycontent = res.data.data.attributes.PrivacyContent;
			const linkv = res.data.data.attributes.Link;
			this.setState({ pagetitle,introcontent,privacyheading,jobpageheading,linktext,privacycontent,linkv });
            //console.log(sliderdet);
          });
//alert(4);

      }
 handleClickEvent(e, value) {
       // var clck = this.state.context.find('#dd'+value);
		//console.log("clicked id:"+clck);
		//alert(e);
		//$("#dslide").current.slickGoTo(e);
		//$('#dslide').slick('slickGoTo', e); 
    } 
      render() {
   // console.log(this.state.sliderdet);
	var asr=[];
	asr=this.state.sliderdet;
	var qrtldt=[];
	qrtldt=this.state.tabcontentdet;
	
	var intrc=this.state.introcontent;
var actnm='s';
	var pth='https://alizzapi.alizzislamic.com';
	document.title = this.state.pagetitle;
	var settings = {
    dots: true,
	fade: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
	autoplay: true,
  autoplaySpeed: 4000,
  arrows: false
  };
  //console.log(qrtldt);
    return (
	
	<>
	
<div className="bannerarea pb-4 pt-4">
    <div id="homebanner" className="bannerslider">

          <div className="container">
		  {/*<Slider {...settings}>
            <div className="row">
			{(() => {
        if(data.alizzcareer.data.attributes.BannerImage.data!=null){
              return (<><div className="col-md-6">
				<img src={pth + data.alizzcareer.data.attributes.BannerImage.data.attributes.url} alt={data.alizzcareer.data.attributes.Title} className=" img-fluid"/>
              </div></>); 
        }
    })()}
              <div className="col-md-6 bnrcntbox">
                <div className="box">
                  <h3>{data.alizzcareer.data.attributes.Title}</h3>
                </div>
              </div>
            </div>
			</Slider>*/}
			<div className="row">
			<div className="col-md-12 bnrcntbox hnm">
                  <h3>{this.state.pagetitle}</h3>
              </div>
			</div>
          </div>

    </div>
	</div>
	

  <section class="section careers">
    <div class="container career-container">
      <div class="row">
        <div class="col-md-12">
			<div className="careercnt">
			{(() => {
        if(this.state.introcontent!=null){
				  return (<><div>{parse(this.state.introcontent)}</div></>); 
        }
    })()}
		</div>
          <p class="pt-3">
            <button class="btn btn-secondary" data-bs-toggle="collapse" data-bs-target="#privacydiv">{this.state.privacyheading}</button>
			  </p>
          <div id="privacydiv" class="collapse">
		  {(() => {
        if(this.state.privacycontent!=null){
				  return (<><div>{parse(this.state.privacycontent)}</div></>); 
        }
    })()}
          </div>
          <div class="d-flex justify-content-center">
          <div class="row">
            <div class="col-md-12 text-center">
              <h3 class="pb-3">{this.state.jobpageheading}</h3>
              <a href={this.state.linkv} class="btn btn-primary">{this.state.linktext}</a>
            </div>
          </div>
          </div>

        </div>
      </div>
    </div>
  </section>
	
	


	  </>
	  
    );
  }
    }


export default Careerpage;
    