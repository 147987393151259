import React, { useState, useEffect  } from 'react';
import { graphql } from "@apollo/client/react/hoc";
import { InMemoryCache,ApolloClient ,useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
//import ReactCardSlider from 'react-card-slider-component';
import parse from 'html-react-parser';
import useForm from 'react-hook-form'
import $ from 'jquery';
import jQuery from 'jquery';
import Axios from 'axios';
window.$ = window.jQuery = require('jquery')


class Search extends  React.Component  {
  constructor(props) {
    super(props);
    //this.getID = sessionStorage.getItem('pageid');
    //this.Lang = sessionStorage.getItem('lang');
//alert(this.Lang);


  }
state = {
        sliderdet: [],
      }

      componentDidMount() {
	//var lng= sessionStorage.getItem('lang');


      }
 handleClickEvent(e, value) {
       // var clck = this.state.context.find('#dd'+value);
		//console.log("clicked id:"+clck);
    } 
      render() {
    //console.log(this.props);
	

	var pth='https://alizzapi.alizzislamic.com/';
	   return (
	<>
<section className="section whatyousect">
    <div className="container pt-3 pb-5">
      <div className="row">
        <div className="col-md-12 text-center mb-2">
          {/*<h3>What you like to do today</h3>*/}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 alert alert-success text-center">
		<i className="fa fa-check-circle-o succcheck"></i>
		<h3 className="page-title">شكراً جزيلاً</h3>
		<p>تم تقديم معاماتك بنجاح<br/>
		سيتم التواصل معك قريباً</p>		
		</div>
	  </div>
	  
	  
	</div>
</section>



</>)
         
  }
    }


export default Search;
    