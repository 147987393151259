import React, { useState, useEffect  } from 'react';
//import { graphql } from "@apollo/client/react/hoc";
//import { InMemoryCache,ApolloClient ,useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
//import ReactCardSlider from 'react-card-slider-component';
import parse from 'html-react-parser';
//import useForm from 'react-hook-form'
import $ from 'jquery';
import jQuery from 'jquery';
import Axios from 'axios';
import withRouter from '../pages/withRouter';
//import lnggg from '../language.json';
window.$ = window.jQuery = require('jquery');

/*const locales = {
  en: { 
  firstname:'First Name',
  lastname:'Last Name',
  mobile:'Mobile',
  email:'Email',
  wilayat:'Wilayat',
  iagree:'I Agree, check out our',
  privacypolicy:'Privacy Policy',
  apply:'Apply',
  applicationprocess:'Application Process',
  errorfirstname:'please enter first name',
  errorlastname:'please enter last name',
  errormobile:'please enter mobile number',
  erroremail:'please enter valid email',
  errorwilayat:'please enter wilayat',
  successsent:'Sent Successfully',
  select:'Select',
  applyfor:'Apply For Home Finance',
  fillinformation:'Kindly fill in all the required information'
  },
  ar: { 
  firstname:'الإسم',
  lastname:'القبيلة',
  mobile:'رقم الهاتف',
  email:'البريد الالكتروني',
  wilayat:'ولاية',
  iagree:'أوافق، اطلع على سياسة الخصوصية',
  privacypolicy:'سياسة العمل',
  apply:'تقديم الطلب',
  applicationprocess:'طريقة التقديم',
  errorfirstname:'please enter first name',
  errorlastname:'please enter last name',
  errormobile:'please enter mobile number',
  erroremail:'please enter valid email',
  errorwilayat:'please enter wilayat',
  successsent:'شكرا على ملاحظتك القيمة , سوف يتم الرد خلال يوم عمل واحد.',
  select:'اختر ',
  applyfor:'تقديم الطلب',
  fillinformation:'الرجاء تعبئة جميع البيانات المطلوبة'
  }
};*/
//var lgng= sessionStorage.getItem('lang');
//const langcontent=locales[lgng];
//console.log(langcontent);

/*const script = document.createElement("script");
    script.src = "/js/card-slider-min.js";
    script.async = true;
    document.body.appendChild(script);
	const cscript = document.createElement("script");
    cscript.src = "/js/expand.js";
    cscript.async = true;
    document.body.appendChild(cscript);*/
	

class Formtab extends  React.Component  {
  constructor(props) {
    super(props);
    //this.getID = sessionStorage.getItem('pageid');
    //this.Lang = sessionStorage.getItem('lang');
//alert(this.Lang);
    //this.storageChanged = this.storageChanged.bind(this);

  }
state = {
		personalhead:'',
		firstname: '',
		lastname: '',
		mobile: '',
		email: '',
		wilayat: '',
		remember: '',
		vtype: '',
		page: '',
      }
 firstnamechange = event => {this.setState({ firstname: event.target.value })}
 lastnamechange = event => {this.setState({ lastname: event.target.value })}
 mobilechange = event => {this.setState({ mobile: event.target.value })}
 emailchange = event => {this.setState({ email: event.target.value })}
 wilayatchange = event => {this.setState({ wilayat: event.target.value })}
 rememberchange = event => {this.setState({ remember: event.target.value })}
 
onSubmit = e => {
        e.preventDefault();
		//const { name, value } = e.target;
		//let isError = { ...this.state.isError };
		//let isValid = false;
		
        if (this.state.firstname=="") {
            //console.log(this.state.FirstName)
			//isError.firstname ="please enter the firstname";
			//isValid = false;
			//alert(this.state.firstname);
		this.setState({
			errormsg: "please enter first name",
			succmessage: null
  		});  	
        }else if(this.state.lastname==""){
			//alert(this.state.lastname);
			this.setState({
			errormsg: "please enter last name",
			succmessage: null
  		});  
		}else if(this.state.mobile==""){
			//alert(9);
			this.setState({
			errormsg: "please enter mobile number",
			succmessage: null
  		});  
		}else if(this.state.email==""){
			this.setState({
			errormsg: "please enter valid email",
			succmessage: null
  		});  
		}else if(this.state.wilayat==""){
			this.setState({
			errormsg: "please enter wilayat",
			succmessage: null
  		});  
		}else{
			//const getLang = sessionStorage.getItem('lang');
const rst = { FirstName: this.state.firstname, LastName: this.state.lastname, Mobile: this.state.mobile, Email: this.state.email, Wilayat: this.state.wilayat,Pages: this.state.page.value,locale:'en'}
const dta=JSON.stringify({data:rst});
			//alert(dta);
			
			Axios({
     method: "post",
     url: "https://alizzapi.alizzislamic.com/api/personalforms",
     data: dta,
     headers: { "Content-Type": "application/json; charset=utf-8" },
   })
      .then(res => {
        //console.log(res);
       // console.log(res.data);
		this.setState({
    succmessage: 'Sent Successfully',
	errormsg: null,
  });
      })
	  .catch(error => {
  this.setState({
    //message: error,
	errormsg: error,
    succmessage: null,
  });
  //console.log(error);
})
			
			//alert(this.state.firstname);
		}
    };
	

	
    /*formValChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
		//alert(name);
        let isError = { ...this.state.isError };
		//alert(isError);
        switch (name) {
            case "FirstName":
                isError.FirstName =
				value.length =="" ? "please enter the firstname" : "";
                break;
            default:
                break;
        }
        this.setState({
            isError,
            [name]: value
        })
    };*/
	
	
displaySlider = () => {
    const sdata = this.props.data;
	//const { register, handleSubmit, errors } = useForm();
	var pth='https://alizzapi.alizzislamic.com/';
        return (
		<>
		<div  className="slide">
		
              </div>
			  </>
		);

  };


      componentDidMount() {
	//var lng= sessionStorage.getItem('lang');
	Axios.get("https://alizzapi.alizzislamic.com/api/homefinance?locale=en&populate[0]=FormDisplay")
		  //Axios.get("http://localhost:1337/api/homefinance?locale="+lng+"&populate[0]=FormDisplay")
        //Axios.get('./menu.json')
          .then(res => {
			const formdisp = res.data.data.attributes.FormDisplay;
			const introcnt = res.data.data.attributes.IntroductionContent;
			this.setState({ formdisp,introcnt});
           // console.log(tabs);
          });
//alert(4);

    	setTimeout(
    function() {
	$('.appl').click(function(){
		$('html, body').animate({
        scrollTop: $('#formdv').offset().top - 120 //#DIV_ID is an example. Use the id of your destination on the page
    }, 'slow');
	});
    }
    .bind(this),
    1000
);  


      }
 handleClickEvent(e, value) {
       // var clck = this.state.context.find('#dd'+value);
		//console.log("clicked id:"+clck);
    } 
      render() {
    //console.log(this.props);
	const { isError } = this.state;
	//var asr=[];
	//asr=this.state.sliderdet;
	//var apprcs=[];
	//apprcs=this.state.applicprocess;
	
	//console.log(apprcs);
	
	//console.log(hash);
	var pth='https://alizzapi.alizzislamic.com/';
    return (
	<>

<section className="calc-inner" id="formdv">
        <div className="container pb-5">
          <div className="row  d-flex justify-content-center">
			{(() => {
            if(this.state.introcnt!=null ){
				return (<><div className="col-md-7">
				{parse(this.state.introcnt)}
				</div></>); 
        }
    })()}

{(() => {
        if(this.state.formdisp!=null && this.state.formdisp!=false ){
	var ind=this.onSubmit;
		var err=this.state.errormsg;
		var fnm=this.firstnamechange;
		var lst=this.lastnamechange;
		var mbl=this.mobilechange;
		var eml=this.emailchange;
		var wil=this.wilayatchange;
		var succ=this.state.succmessage;
            return (<><div className="col-md-5">
              <h4>Apply For Home Finance</h4>
              <p>Kindly fill in all the required information </p>
              <div>
			  {(() => {
        if(err!=null){
	return (
                <>
				<p className="alert alert-danger">{err}</p>
                </>
                );
}
})()}
{(() => {
        if(succ!=null){
	return (
                <>
				<p className="alert alert-success">{succ}</p>
                </>
                );
}
})()}
                <form  onSubmit={ind} className="finfrm">

                  <div className="mb-3 mt-3 form-group">
                    <label htmlFor={`firstname`}>First Name</label>
                    <input type="text" className="form-control" id={`firstname`} placeholder="" name={`firstname`} onChange={fnm} />

                  </div>
                  <div className="mb-3 mt-3 form-group">
                    <label htmlFor={`lastname`}>Last Name</label>
                    <input type="text" className="form-control" id={`lastname`} placeholder="" name={`lastname`} onChange={lst}/>
                  </div>
                  <div className="mb-3 mt-3 form-group">
                    <label htmlFor={`mobile`}>Mobile</label>
                    <input type="number" className="form-control" id={`mobile`} placeholder="" name={`mobile`} onChange={mbl}/>
                  </div>
                  <div className="mb-3 mt-3 form-group">
                    <label htmlFor={`email`}>Email</label>
                    <input type="email" className="form-control" id={`email`} placeholder="" name={`email`} onChange={eml}/>
                  </div>
                  <div className="mb-3 mt-3 form-group">
                    <label htmlFor={`wilayat`}>Wilayat</label>
                    <input type="text" className="form-control" id={`wilayat`} placeholder="" name={`wilayat`} onChange={wil}/>
                  </div>
                  <div className="form-check mb-3 form-group">
                    <label className="form-check-label">
                      <input className="form-check-input" type="checkbox" name={`remember`}/> I Agree, check out our <a
                        href="/privacy-policy">Privacy Policy</a>
                    </label>
                  </div>
				 
				  <input type="hidden" value='Homefinance' name='page' ref={(input) => { this.state.page = input }}/>
                  <button type="submit" className="btn btn-primary sty1 mx-2">Apply</button>
                </form>

              </div>
            </div></>
                );
}
})()}
			
			

          </div>
        </div>
      </section>


	  </>
	  
    );
  }
    }


//export default Formtab;
export default withRouter(Formtab);
    