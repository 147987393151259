import React, { useState, useEffect  } from 'react';
//import { graphql } from "@apollo/client/react/hoc";
//import { InMemoryCache,ApolloClient ,useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
//import ReactCardSlider from 'react-card-slider-component';
import parse from 'html-react-parser';
//import useForm from 'react-hook-form'
import $ from 'jquery';
import jQuery from 'jquery';
import Axios from 'axios';
import withRouter from '../pages/withRouter';
//import lnggg from '../language.json';
window.$ = window.jQuery = require('jquery');

const locales = {
  ar: { 
  firstname:'dsf dfdf sdf'
  }
};
//var lgng= sessionStorage.getItem('lang');
const langcontent=locales['ar'];
//console.log(langcontent);

/*const script = document.createElement("script");
    script.src = "/js/card-slider-min.js";
    script.async = true;
    document.body.appendChild(script);
	const cscript = document.createElement("script");
    cscript.src = "/js/expand.js";
    cscript.async = true;
    document.body.appendChild(cscript);*/
	

class Tharwaslider extends  React.Component  {
  constructor(props) {
    super(props);
    //this.getID = sessionStorage.getItem('pageid');
    //this.Lang = sessionStorage.getItem('lang');
//alert(this.Lang);
    //this.storageChanged = this.storageChanged.bind(this);

  }
state = {
        sliderdet: [],
     }
	
	
displaySlider = () => {
    const sdata = this.props.data;
	//const { register, handleSubmit, errors } = useForm();
	var pth='https://alizzapi.alizzislamic.com/';
        return (
		<>
		<div  className="slide">
		
              </div>
			  </>
		);

  };


      componentDidMount() {
	//var lng= sessionStorage.getItem('lang');
	Axios.get("https://alizzapi.alizzislamic.com/api/tharwa?locale=ar&populate[0]=TharwaBoxSlider.CoverImage")
        //Axios.get('./menu.json')
          .then(res => {
            const sliderdet = res.data.data.attributes.TharwaBoxSlider;
			this.setState({ sliderdet});
           // console.log(tabs);
          });
//alert(4);

    	setTimeout(
    function() {
        var slr =$('#slider').cardSlider({
      slideTag: 'div'
      , slideClass: 'slide'
      , current: 1
      , followingClass: 'slider-content-6'
      , delay: 3000
      , transition: 'ease'
      , onBeforeMove: function (slider, onMove) {
        //console.log('onBeforeMove')
        onMove()
      }
      , onMove: function (slider, onAfterMove) {
        onAfterMove()
      }
      , onAfterMove: function (index) {
        //console.log('onAfterMove');
		//alert(index);
        var act=$(".center-slide .sldbox").attr("vl");
		$(".expntcnt").removeClass("active");
        $("#" + act).addClass("active");
		$('html, body').animate({
        scrollTop: $('#'+act).offset().top - 120 //#DIV_ID is an example. Use the id of your destination on the page
    }, 'slow');
      }
      , onAfterTransition: function () {
        //console.log('onAfterTransition')
      }
      , onCurrent: function () {
        //console.log('onCurrent')
      }
    });
	
	//$("#slider .slide").removeClass("left-hidden-slide center-slide right-hidden-slide").addClass("hidden-slide");
	//$("#slide2").addClass("center-slide");
	//$("#slide2").removeClass("hidden-slide");
	

	
    }
    .bind(this),
    1500
);  


      }
 handleClickEvent(e, value) {
       // var clck = this.state.context.find('#dd'+value);
		//console.log("clicked id:"+clck);
    } 
      render() {
    //console.log(this.props);
	const { isError } = this.state;
	var asr=[];
	asr=this.state.sliderdet;
	
	//console.log(asr);
	//let hash = window.location.hash.substring(1);
	const product =  window.location.search.split('v=')[1];
	//alert(product);

	var success = [];
     for(var i=0;i<asr.length;i++)
     {
        if(asr[i]['Slug'] ==product)
        {
         success[0] = [asr[i]];
        }
     }
	 
	 var successw = [];
     for(var i=0;i<asr.length;i++)
     {
        if(asr[i]['Slug'] !=product)
        {
         successw[i] = [asr[i]];
        }
     }
	 var array1=[];
	 array1 = success.concat(successw);
	 array1.sort();
	//console.log(array1);
	
	//console.log(hash);
	var pth='https://alizzapi.alizzislamic.com/';
    return (
	<>
      <div className="slidertab">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div id="slider" className="ui-card-slider trwa">
			{/*this.displaySlider()*/}
	  {           
          array1.map( (prdtl, index) =>
            {	
var slidevl="slide";
			var dd="dd";
return(<><div key={prdtl[0].id} className="slide" id={slidevl+index}>
{(() => {
            if(prdtl[0].CoverImage.data!=null ){
				return (<><div className="sldbox" vl={dd+index} style={{ background:`url(${pth+prdtl[0].CoverImage.data.attributes.url}) no-repeat` ,backgroundSize:"100%"}}  onClick={this.handleClickEvent.bind(this, index)}>
                  <h2>{prdtl[0].Title} </h2>	
				  <span></span>		  
                </div></>); 
        }else{
	return (<><div className="sldbox" vl={dd+index}   onClick={this.handleClickEvent.bind(this, index)}>
                  <h2>{prdtl[0].Title} </h2>	
				  <span></span>			  
                </div></>); 
}
    })()}

              </div></>)
          })
        }
	   </div>
          </div>
        </div>
      </div>
    </div>


<div className="slidercnt">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
{           
          array1.map( (prdt2, index) =>
            {	
			var dd="dd";
			//console.log(prdt2);
			var ml=index;
return(<>
<div key={prdt2[0].id} id={dd+index}  className={`expntcnt ${index==0 ? "active" : ""}`}>
 {(() => {
        if(prdt2[0].Content!=null && prdt2[0].Content!=""){
				return (<><div className="contdv">{parse(prdt2[0].Content)}</div></>); 
        }
    })()}
</div></>)
          })
        }
</div>
</div>
</div>
</div>
	  </>
	  
    );
  }
    }


//export default Tharwaslider;
export default withRouter(Tharwaslider);
    