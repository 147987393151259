//Make the necessary imports
import React from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client'
import parse from 'html-react-parser';
//Create the query
const PRESSRELEASE = gql`
query GetPressrelease($id: ID!) {
        pressrelease(id: $id) {
          data {
            id
            attributes {
              Title
              Content
			  Cover{
                data{
                  attributes{
                    url
                  }
                }
              }
            }
          }
        }
}
`

export default function PressreleaseDetails() {
    //Get the id from the URL
    const { id } = useParams();
let navigate = useNavigate();
    //Pass variables to the query and execute it. Store the results in an object
    const { loading, error, data } = useQuery(PRESSRELEASE, {
        variables: { id: id }
      })

    //Display messages accordingly
      if (loading) return <p>Loading...</p>
      if (error) return <p>Error :(</p>
    var pth='https://alizzapi.alizzislamic.com/';
      //console.log(data);
const shorten = data.pressrelease.data.attributes.Content ? data.pressrelease.data.attributes.Content : '';
  return (
    <div className="pressdet">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
		  <p className="alnrgt"><button onClick={() => navigate(-1)} className="backbtn">Back</button></p>
	<img src={pth + data.pressrelease.data.attributes.Cover.data.attributes.url} alt={data.pressrelease.data.attributes.Title} className="prsimg"/>
        <div className='text-2xl'>
            <h2>{data.pressrelease.data.attributes.Title}</h2>
        </div>

        <div className='mt-2 mb-2'>
            {parse(shorten)}
        </div>

    </div></div></div></div>
  );
}