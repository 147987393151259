import React, { useState, useEffect,useRef  } from 'react';
import { graphql } from "@apollo/client/react/hoc";
import { InMemoryCache,ApolloClient ,useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
//import ReactCardSlider from 'react-card-slider-component';
import Slider, { slickGoTo } from "react-slick";
import Customerinquiryform from './Customerinquiryform';
import parse from 'html-react-parser';
import $ from 'jquery';
import jQuery from 'jquery';
import Axios from 'axios';
window.$ = window.jQuery = require('jquery')
/*const script = document.createElement("script");
    script.src = "/js/card-slider-min.js";
    script.async = true;
    document.body.appendChild(script);*/
/*const locales = {
  en: { 
  clickhere:'Click here',
  forlocationmap:'for Location Map'
  },
  ar: { 
  clickhere:'Click here',
  forlocationmap:'for Location Map'
  }
};
var lgng= sessionStorage.getItem('lang');
const langcontent=locales[lgng];*/
class Branchlocations extends  React.Component  {
  constructor(props) {
    super(props);
    //this.getID = sessionStorage.getItem('pageid');
    //this.Lang = sessionStorage.getItem('lang');
//alert(this.Lang);
    //this.storageChanged = this.storageChanged.bind(this);
  }

state = {
        sliderdet: [],
      }
      componentDidMount() {
	
	//var lng= sessionStorage.getItem('lang');
	Axios.get("https://alizzapi.alizzislamic.com/api/branchlocator?locale=en&populate[0]=FeaturedImage,Banner,BranchDetails")
        //Axios.get('./menu.json')
          .then(res => {
			const pagetitle = res.data.data.attributes.Title;
			const introcontent = res.data.data.attributes.Content;
			const featuredimage = res.data.data.attributes.FeaturedImage;
            const banner = res.data.data.attributes.Banner;
			const branchdetails = res.data.data.attributes.BranchDetails;
			this.setState({ pagetitle,introcontent,featuredimage,banner,branchdetails });
            //console.log(branchdetails);
          });
//alert(4);

      }
 handleClickEvent(e, value) {
       // var clck = this.state.context.find('#dd'+value);
		//console.log("clicked id:"+clck);
		//alert(e);
		//$("#dslide").current.slickGoTo(e);
		//$('#dslide').slick('slickGoTo', e); 
    } 
      render() {
   // console.log(this.state.sliderdet);
	var pth='https://alizzapi.alizzislamic.com';
	document.title = this.state.pagetitle;
	var settings = {
    dots: true,
	fade: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
	autoplay: true,
  autoplaySpeed: 4000,
  arrows: false
  };
  //console.log(qrtldt);
    return (
	
	<>
	
<div className="bannerarea pb-4 pt-4">
    <div id="homebanner" className="bannerslider">

          <div className="container">
		  {/*<Slider {...settings}>
            <div className="row">
			{(() => {
        if(data.branchlocator.data.attributes.Banner.data!=null){
              return (<><div className="col-md-6">
				<img src={pth + data.branchlocator.data.attributes.Banner.data.attributes.url} alt={data.branchlocator.data.attributes.Title} className=" img-fluid"/>
              </div></>); 
        }
    })()}
              <div className="col-md-6 bnrcntbox">
                <div className="box">
                  <h3>{data.branchlocator.data.attributes.Title}</h3>
                </div>
              </div>
            </div>
			</Slider>*/}
			<div className="row">
			<div className="col-md-12 bnrcntbox hnm">
                  <h3>{this.state.pagetitle}</h3>
              </div>
			</div>
			
          </div>

    </div>
	</div>
	
<section className="section branch-locator">
    <div className="container branch-container">
      <div className="row">
        <div className="col-md-12">
		  {(() => {
			  if(this.state.introcontent!=null && this.state.introcontent!=""){
				return (<><div className="fcnt">{parse(this.state.introcontent)}</div></>); 
        }
		 })()}	
          </div>
          {/*<div className="col-md-3 pb-5">
			<img src={pth + data.branchlocator.data.attributes.FeaturedImage.data.attributes.url} alt={data.branchlocator.data.attributes.Title} className="float-end img-fluid"/>
          </div>*/}
          </div>

          <div className="row d-inline-flex justify-content-center">
		  
		  {           
          this.state.branchdetails?.map( (prdtl, index) =>
            {	
			var dd="dd";
return(<>
<div key={prdtl.id} className="col-md-4 bg-branch">
            <h4>{prdtl.Title}</h4>
           
			{(() => {
			  if(prdtl.Content!=null && prdtl.Content!=""){
				return (<><p>{parse(prdtl.Content)}</p></>); 
        }
		 })()}	
            <p className="loclink"> <a href={prdtl.LocationMap} target="_blank"> 
			<span>Click here</span>
			</a> <span>for Location Map</span></p>
          </div></>)
          })
        }


      </div>
    </div>
  </section>


	  </>
	  
    );
  }
    }


export default Branchlocations;
    