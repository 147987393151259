//Make the necessary imports
import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import parse from 'html-react-parser';
//import SlickSlider from "./Slider";
//import Homefixedquicklinks from "../components/Homefixedquicklinks";
//import Homequicklinks from "../components/Homequicklinks";
import Businesstabs from "../components/Businesstabsfinancear";
//import Hometabslider from "../components/Hometabslider";
//import Homegetintouch from "../components/Homegetintouch";

//GraphQL query to fetch all the blogs from the backend
export const SLIDESHOW = gql`
{
       business {
      data {
        id
        attributes {
          localizations{
            data{
              attributes{
                Slider{
                  Title
                  Subtitle
                  Description
                  ButtonText
                  ButtonLink
                  Class
                  Cover{
                    data{
                      attributes{
                        name
                        url
                      }
                    }
                  }
                }
              }
            }
          }
            Slider{
              Title
              Subtitle
              Description
              ButtonText
              ButtonLink
              Class
              Cover{
               data{
                id
                attributes{
                  name
				  url
                  width
                height
                size
                formats
                createdAt
                previewUrl
                }
              }
              }
              }
              }
            }
          }
          }
`

/*const fscript = document.createElement("script");
    fscript.src = "/js/jquery.flexslider-min.js";
    fscript.async = true;
    document.body.appendChild(fscript);*/
	
/*const script = document.createElement("script");
    script.src = "/js/home.js";
    script.async = true;
    document.body.appendChild(script);*/
export default function Business() {
document.title = 'Business'	
	
	var pth='https://alizzapi.alizzislamic.com';
var settings = {
    dots: true,
	fade: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
	autoplay: true,
  autoplaySpeed: 4000,
  arrows: false
  };
//Execute the query using the useQuery hook and store the return values.
const { loading, error, data } = useQuery(SLIDESHOW);
//console.log(data);

if (loading) return <p>Loading...</p>

if (error) return <p>Error :(</p>
const getLang = sessionStorage.getItem('lang');
//console.log(data.pressReleases.data);
  return (
  <>
  <div className="bannerarea pb-4 pt-4">
	<div id="homebanner" className="flexsliders">
	<div className="container">
    <Slider {...settings}>
      {
            data.business.data.attributes.localizations.data[0].attributes.Slider.map((aslide,i) => (         
            <div  key={aslide+i} className="row">
              <div className="col-md-6">
                <img src={pth + aslide.Cover.data.attributes.url} alt={aslide.Title} className="img-fluid"/>
              </div>
              <div className="col-md-6 bnrcntbox">
                <div className="box">
                  <h5>{aslide.SubTitle}</h5>
                  <h3>{aslide.Title}</h3>
                  <div className="bnrcnt">{parse(aslide.Description)}</div>
				 
				  {(() => {
        if(aslide.ButtonText!="" && aslide.ButtonText!=null){
	return (<> <a href={aslide.ButtonLink} className='btn btn-primary sty1 mx-2'>{aslide.ButtonText}</a></>); 
        }
    })()}
                </div>
              </div>
            </div>
          
		))
          }
    </Slider>
	</div>
	</div>
	</div>
	{/*<Homefixedquicklinks/>*/}
	<Businesstabs/>
	{/*<Homequicklinks/>*/}
	{/*<Hometabslider/>
	<Homegetintouch/>*/}
	  </>
  );
}