import React from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import withRouter from './withRouter';
// import { List, Card, Form } from 'antd';

//const FormItem = Form.Item;

class FormUser extends React.Component {
 //const { id } = useParams() 
  // constructor(props) {
  //   super(props)
  //   this.state = {
  state = {
    Name: '',
    Age: '',
    DOB: '',
    Qualification: '',
  };

  handleNameChange = event => {this.setState({ Name: event.target.value })}
  handleAgeChange = event => {this.setState({ Age: event.target.value })}
  handleDOBChange = event => {this.setState({ DOB: event.target.value })}
  handleQualificationChange = event => {this.setState({ Qualification: event.target.value })}

  handleSubmit = event => {
    event.preventDefault();

    // const userform = {userid: this.state.userid};
    // const fullnameForm = {fullname: this.state.fullname};
    // const usergroupForm = {usergroup: this.state.usergroup};
    // const emailidForm = {emailid: this.state.emailid};
    // const mobileForm = {mobile: this.state.mobile};
    // const titleForm = {title: this.state.title};

const rst = { Name: this.state.Name, Age: this.state.Age, DateofBirth: this.state.DOB, Qualification: this.state.Qualification }
const dta=JSON.stringify({data:rst});
//const cc=this.formatDate('14/05/1984');
//var d = new Date(this.state.DOB);
//var mnth=d.getMonth() + 1;
//const todayDate = cc.toISOString().slice(0, 10);
//alert(dta);
    //axios.post('https://alizzapi.alizzislamic.com/api/careers-forms',dta)
	axios({
     method: "post",
     url: "https://alizzapi.alizzislamic.com/api/careers-forms",
     data: dta,
     headers: { "Content-Type": "application/json; charset=utf-8" },
   })
      .then(res => {
        //console.log(res);
       // console.log(res.data);
		this.setState({
    message: 'Success!'
  });
      })
	  .catch(error => {
  this.setState({
    //message: error,
	message: "All fields required",
    error: error,
  });
  //console.log(error);
})
  }

  render() {
	  //console.log(this.props.params.id);
	  
    return (
	<>
<section class="section careers">
    <div class="container career-container">
      <div class="row">
        <div class="col-md-12">
{(() => {
        if(this.state.message!=""){
	return (
                <>
				<p class="msg">{this.state.message}</p>
                </>
                );
}
})()}
      <form onSubmit={this.handleSubmit}>
              <input
                type="text"
                placeholder="Name"
                name="this.state.Name" onChange={this.handleNameChange}
              />
              <input
                type="text"
                placeholder="Age"
                name="this.state.Age" onChange={this.handleAgeChange}
              />
              <input
                type="text"
                placeholder="Date of Birth"
                name="this.state.DOB" onChange={this.handleDOBChange}
              />
			  <input
                type="text"
                placeholder="Qualification"
               name="this.state.Qualification" onChange={this.handleQualificationChange}
              />
			  {<input
                type="hidden"
                name="CareerId"
				value={this.props.params.id}
              />}
              <button type="submit">Submit</button>
            </form>
			</div>
			</div>
			</div>
			</section>
</>
    )
  }
}

export default withRouter(FormUser);