import React, { useState, useEffect  } from 'react';
import { graphql } from "@apollo/client/react/hoc";
import { InMemoryCache,ApolloClient ,useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
//import ReactCardSlider from 'react-card-slider-component';
import parse from 'html-react-parser';
import $ from 'jquery';
import jQuery from 'jquery';
import Axios from 'axios';
window.$ = window.jQuery = require('jquery')

export const MAINTABS = gql`
{
    account {
      data {
id
        attributes {
            Slider {
			id
              Title
              Subtitle
              ShortText
              LinkText
              Link
              Icon{
               data{
                id
                attributes{
                  name
				  url
                  width
                height
                size
                formats
                createdAt
                previewUrl
                }
              }
              }
              }
              }
            }
          }
          }
`

	/*const cscript = document.createElement("script");
    cscript.src = "/js/cardslider.js";
    cscript.async = true;
    document.body.appendChild(cscript);*/
	
class Pressrelease extends  React.Component  {
  constructor(props) {
    super(props);
    //this.getID = sessionStorage.getItem('pageid');
    //this.Lang = sessionStorage.getItem('lang');
//alert(this.Lang);
    //this.storageChanged = this.storageChanged.bind(this);
	  const year = (new Date()).getFullYear();
  this.years = Array.from(new Array(20),(val, index) => index - year);
  }

state = {
        pressdet: [],
	actdet: []
      }
      componentDidMount() {

	 //var lng= sessionStorage.getItem('lang');
	Axios.get("https://alizzapi.alizzislamic.com/api/pressreleases?locale=ar&sort[0]=Date:desc&populate=*")
        //Axios.get('./menu.json')
          .then(res => {
            const pressdet = res.data;
			this.setState({ pressdet });
            //console.log(sliderdet);
          });
//alert(4);

          $(".s012013").remove();
	      $(".s022013").remove();
	      $(".s032013").remove();
	      $(".s062013").remove();
	      $(".s042014").remove();
	      $(".s042015").remove();
	      $(".s052015").remove();
		  $(".s112018").remove();
		  $(".s082020").remove();
		  $(".s072020").remove();
		  $(".s052020").remove();
		  $(".s042020").remove();
		  $(".s022023").remove();
          $(".s042023").remove();
      }
isDateGreaterThanToday(b) {
    var dS = b.split("/");
    var d1 = new Date(dS[1], (+dS[0] - 1));
    var today = new Date();
    if (d1 > today) {
        //return "D is greater";
		return 0;
    } else {
        //return "today is greater";
		return 1;
    }
}
pressFetch(vl) {
    //alert(vl);
	//var lng= sessionStorage.getItem('lang');
	Axios.get("https://alizzapi.alizzislamic.com/api/pressreleases?locale=ar&filters[$or][0][Date][$startsWith]="+vl+"&sort[0]=Date:desc&populate=*")
        //Axios.get('./menu.json')
          .then(res => {
            const pressdet = res.data;
		const actdet = vl;
			this.setState({ pressdet,actdet });
            //console.log(pressdet);
          });
  }
 handleClickEvent(e, value) {
        var myDomEl = this.state.context.find('#dd'+value);
    } 
      render() {
    //console.log(this.props);
	//var asr=[];
	//asr=this.state.pressdet.data;
	//console.log(this.state.pressdet);
	var pth='https://alizzapi.alizzislamic.com/';
		  //const getLang = sessionStorage.getItem('lang');
		  
		  const options = [];
    const thisYear = (new Date()).getFullYear();
    for (let i = thisYear; i >= 2013; i--) {
      //const yyear = thisYear - i;
      options.push(<option value={i}>{i}</option>);
    }
	const yrs = [];
    for (let i = thisYear; i >= 2013; i--) {
      //const yyear = thisYear - i;
      yrs.push(i);
    }
	var monthNameList = ["December", "November", "October", "September", "August", "July", "June", "May", "April", "March", "February", "January"];
		
		//console.log(monthNameList);
    return (
	<>
	<div className="bannerarea pb-4 pt-4">
    <div id="homebanner" className="bannerslider">

          <div className="container">
		  {/*<Slider {...settings}>
            <div className="row">
			{(() => {
        if(data.branchlocator.data.attributes.Banner.data!=null){
              return (<><div className="col-md-6">
				<img src={pth + data.branchlocator.data.attributes.Banner.data.attributes.url} alt={data.branchlocator.data.attributes.Title} className=" img-fluid"/>
              </div></>); 
        }
    })()}
              <div className="col-md-6 bnrcntbox">
                <div className="box">
                  <h3>{data.branchlocator.data.attributes.Title}</h3>
                </div>
              </div>
            </div>
			</Slider>*/}
			<div className="row">
			<div className="col-md-12 bnrcntbox hnm">
                  <h3>
		<span>أخبار صحفية</span>
		</h3>
              </div>
			</div>
			
          </div>

    </div>
	</div>
{/*	  {yrs}
<button onClick={() => this.sayHello(2023)}>
      2023
    </button>
<select>
     {
       this.years.map((year, index) => {
         return <option key={`year${index}`} value={year}>{year}</option>
       })
     }
    </select>
<select  > 
          {options}
        </select>*/}

<div className="presslist">
      <div className="container">
<div className="row">
          <div className="col-md-12 tnpnlinr">
{
       yrs.map((year, index) => {
         return (<>		  
			  
<div className="card" key={`year${index}`}>
	<div className="ex_title">{year}</div>
	<div className="ex_cnt">

<div className="row">
					<div className="col-md-12 monthout">
					{

                     monthNameList.map((mnth, index) => {
						if(mnth==="December"){
						var mn=12;
						
                            var mnn="ديسمبر";
                        
						}
						if(mnth==="November"){
						mn=11;
						
                            mnn="نوفمبر";
                        
						}
						if(mnth==="October"){
						mn=10;

                            mnn="اكتوبر";
                        
						}
if(mnth==="September"){
						mn='09';

                            mnn="سبتمبر";
                        
						}
if(mnth==="August"){
						mn='08';

                            mnn="اغسطس";
                        
						}
if(mnth==="July"){
						mn='07';

                            mnn="يوليو";
                        
						}
if(mnth==="June"){
						mn='06';

                            mnn="يونيو";
                        
						}
if(mnth==="May"){
						mn='05';

                            mnn="مايو";
                        
						}
if(mnth==="April"){
						mn='04';

                            mnn="ابريل";
                        
						}
if(mnth==="March"){
						mn='03';

                            mnn="مارس";
                        
						}
if(mnth==="February"){
						mn='02';

                            mnn="فبراير";
                        
						}
if(mnth==="January"){
						mn='01';

                            mnn="يناير";
                        
						}
var dt = new Date();
var fg=dt.getMonth()+1;
var yr=dt.getFullYear();
var sx=this.isDateGreaterThanToday(mn+'/'+year);
var s="s";
if(sx==1){
//console.log(this.state.pressdet);
                       return (<>
								<div className={`card ${s+mn+year}`}  key={`mnth${index}`}>
								<div className="tx_title" onClick={() => this.pressFetch(year+'-'+mn)}>{mnn}</div>
								<div className="tx_cnt">
<div className="tbn">
{           

          this.state.pressdet.data?.map( (prdtl, index) =>
            {	
			//var dd="dd";
if(this.state.actdet===year+'-'+mn){
const shorten = prdtl.attributes.Content ? prdtl.attributes.Content.substring(0, 150) : '';
return(<><div key={prdtl.id} className='mt-2 mb-4 prlst bg-white rounded-md'>
					<div className="row">
					<div className="col-md-2">

					 <img src={pth + prdtl.attributes.Cover.data.attributes.url} alt={prdtl.Title} className="prsimg"/>
					</div>
					<div className="col-md-10">
					<div className='text-xl'>
                        {prdtl.attributes.Title}
                    </div>

                    <small>
                        {prdtl.attributes.Author}
                    </small>

                    {/* Display only the first 150 characters of the body */}
                    <div>
                        {parse(shorten)}
                    </div>

                    {/* Link to display the whole blog content */}
                    <Link to={`/the-bank/media/press-releases/${prdtl.id}`} className='text-purple-600'><span>أقرأ أكثر...</span></Link>
					</div>
					</div>
                    
                </div>
</>)
}     })
        }
</div>

</div>
								</div>
								
                              </>)
}
                        })
                      }
					</div>
				</div>



</div>
</div>
</>
);
       })
     }
</div>
</div>
</div>
</div>

	
	  </>
	  
    );
  }
    }


export default Pressrelease;
    