import React, { useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import withRouter from './withRouter';
//import emailjs from '@emailjs/browser';
//import emailjs from 'emailjs-com';
// import { List, Card, Form } from 'antd';

//const FormItem = Form.Item;
const locales = {
  ar: { 
  type: 'النوع' ,
  query:'الاستفسار',
  name:'الاسم',
  email:'البريد الالكتروني',
  phonenumber:'رقم هاتف النقال',
  select:'اختار',
  commentgoeshere:'بامكانك كتابة ملاحظاتك هنا',
  submit:'تسليم',
  reset:'إعادة ضبط',
  inquiry:'الطلب',
  complaint:'شكوى',
  suggestion:'اقتراح',
  feedback:'ملاحظة',
  errortype:'الرجاء قم باختيار نوع الطلب',
  errorquery:' الرجاء قم باختيار نوع الاستفسار ',
  errorname:'الرجاء ادخال الاسم',
  erroremail:'الرجاء ادخال البريد الالكتروني',
  errorphone:'الرجاء ادخال رقم هاتف النقال',
  }
};
//var lgng= sessionStorage.getItem('lang');
const langcontent=locales['ar'];
class InquiryForm extends React.Component {
 //const { id } = useParams() 
  // constructor(props) {
  //   super(props)
  //   this.state = {
  state = {
    Type: '',
    Query: '',
    Name: '',
    Email: '',
	Phone:''
  };

  handleTypeChange = event => {this.setState({ Type: event.target.value })}
  handleQueryChange = event => {this.setState({ Query: event.target.value })}
  handleNameChange = event => {this.setState({ Name: event.target.value })}
  handleEmailChange = event => {this.setState({ Email: event.target.value })}
  handlePhoneChange = event => {this.setState({ Phone: event.target.value })}

  handleSubmit = event => {
    event.preventDefault();
	//const form = useRef();
	if(this.state.Type==""){
		this.setState({
	errormsg: langcontent.errortype,
	succmessage:null
  });  
  //return false;
	  }else if(this.state.Query==""){
		  this.setState({
	errormsg: langcontent.errorquery,
	succmessage:null
  });  
	  }else if(this.state.Name==""){
		  this.setState({
	errormsg: langcontent.errorname,
	succmessage:null
  });  
	  }else if(this.state.Email==""){
		  this.setState({
	errormsg: langcontent.erroremail,
	succmessage:null
  });  
	  }else if(this.state.Phone==""){
		  this.setState({
	errormsg: langcontent.errorphone,
	succmessage:null
  });  
	  }else{
const getLang = sessionStorage.getItem('lang');
const rst = { Type: this.state.Type, Query: this.state.Query, Name: this.state.Name, Email: this.state.Email, Phone: this.state.Phone,locale:getLang }
const dta=JSON.stringify({data:rst});
//const cc=this.formatDate('14/05/1984');
//var d = new Date(this.state.DOB);
//var mnth=d.getMonth() + 1;
//const todayDate = cc.toISOString().slice(0, 10);
//alert(dta);
    //axios.post('http://localhost:1337/api/careers-forms',dta)
const ml = {
    Type: this.state.Type,
    Query: this.state.Query,
    Name: this.state.Name,
    Email: this.state.Email,
	Phone:this.state.Phone
  };
axios({
     method: "post",
     url: "https://alizzapi.alizzislamic.com/api/customer-inquiries",
     data: dta,
     headers: { "Content-Type": "application/json; charset=utf-8" },
   })
      .then(res => {
        //console.log(res);
       // console.log(res.data);
		this.setState({
    succmessage: langcontent.successm,
	errormsg: null,
  });
  this.setState({
      Type: '',
	  Query: '',
	  Name: '',
	  Email: '',
	  Phone: '',
    });
  console.log(event.target);
  /*emailjs.sendForm('service_47o8wmq', 'template_b1tpdxc', event.target, 'XcCb7ZWdIYBQIhD2d')
     .then((result) => {
         console.log(result.text);
     }, (error) => {
        console.log(error.text);
     });*/
  event.target.reset()
      })
	  .catch(error => {
  this.setState({
    //message: error,
	errormsg: langcontent.errorm,
    error: error,
  });
  //console.log(error);
})
	  }
  }

  render() {
	  //console.log(this.props.params.id);
	  
    return (
	<>
{(() => {
        if(this.state.errormsg!=null){
	return (
                <>
				<p className="alert alert-danger">{this.state.errormsg}</p>
                </>
                );
}
})()}
{(() => {
        if(this.state.succmessage!=null){
	return (
                <>
				<p class="alert alert-success">{this.state.succmessage}</p>
                </>
                );
}
})()}

			
			<form onSubmit={this.handleSubmit} >
            <div class="row d-flex pb-4">
              <div class="col-md-3">
                {langcontent.type}<font className="error">*</font></div>
              <div class="col-md-9">
                <select class="form-select" name="Type" onChange={this.handleTypeChange}>
                  <option value="">--{langcontent.select}--</option>
                  <option value={langcontent.inquiry}>{langcontent.inquiry}</option>
                  <option value={langcontent.complaint}>{langcontent.complaint}</option>
                  <option value={langcontent.suggestion}>{langcontent.suggestion}</option>
                  <option value={langcontent.feedback}>{langcontent.feedback}</option>
                </select>
              </div>
            </div>

            <div class="row d-flex pb-4">
              <div class="col-md-3">
                {langcontent.query}<font className="error">*</font>
              </div>
              <div class="col-md-9">
                <textarea class="form-control" id="comment" name="Query" onChange={this.handleQueryChange} placeholder={langcontent.commentgoeshere}></textarea>
              </div>
            </div>

            <div class="row d-flex pb-4">
              <div class="col-md-3">
                {langcontent.name}<font className="error">*</font>
              </div>
              <div class="col-md-9">
                <input type="text" class="form-control" placeholder={langcontent.name} name="Name" onChange={this.handleNameChange}/>
              </div>
            </div>
            <div class="row d-flex pb-4">
              <div class="col-md-3">
                {langcontent.email}<font className="error">*</font>
              </div>
              <div class="col-md-9">
                <input type="text" class="form-control" placeholder={langcontent.email} name="Email" onChange={this.handleEmailChange}/>
              </div>
            </div>
            <div class="row d-flex pb-4">
              <div class="col-md-3">
                {langcontent.phonenumber}<font className="error">*</font>
              </div>
              <div class="col-md-9">
                <input type="text" class="form-control" placeholder={langcontent.phonenumber} name="Phone" onChange={this.handlePhoneChange}/>
              </div>
            </div>

            <div class="row d-flex pb-4">
              <div class="col-md-12 text-center">
                <button type="submit" class="btn btn-primary active">{langcontent.submit}</button>&nbsp;
                <button type="reset" class="btn btn-primary">{langcontent.reset}</button>
              </div>
            </div>
          </form>
</>
    )
  }
}

export default withRouter(InquiryForm);