import React,{useEffect,useState} from "react";
import jQuery from 'jquery';
//import $ from 'jquery';
import { Helmet } from "react-helmet";

//import axios from "axios";
//import { Nav, NavLink, NavMenu }     from "./HeaderElements";
import Topmenu from "./TopMenu";
import Topmenuar from "./TopMenuar";
window.$ = window.jQuery=jQuery;
//require ('flexslider');
//require('./node_modules/jquery/dist/jquery.js');
//require('react-flex-slider/assets/index.css');
//var Slider = require('react-flex-slider');
/*const locales = {
  en: { 
  onlinebanking:'Internet Banking',
  login:'Login'
  },
  ar: { 
  onlinebanking:'الخدمات المصرفية عبر الانترنت',
  login:'دخول'
  }
};
var lgng= sessionStorage.getItem('lang');
const langcontent=locales[lgng];*/

const Header = () => {
	const queryParameters = new URLSearchParams(window.location.search);
	//const type = queryParameters.get("v");
    const [topmenus,getTopmenus,lang, setLang]=useState('');
	const lngg=window.location.pathname.split('/');
	
         
   // const getLang = sessionStorage.getItem('lang');
if(lngg[1]=='ar'){
	document.body.dir = "rtl";
        sessionStorage.setItem('lang', 'ar');
	var path = window.location.protocol + '//' + window.location.host +'/'+ lngg[2] +'/'+lngg[3]; 
	if(lngg[4] && lngg[2] && lngg[3]){
		var path = window.location.protocol + '//' + window.location.host +'/'+ lngg[2] +'/'+lngg[3]+'/'+lngg[4]; 
	}else if(lngg[2] && lngg[3]){
		var path = window.location.protocol + '//' + window.location.host +'/'+ lngg[2] +'/'+lngg[3]; 
		
	}else{
		var path = window.location.protocol + '//' + window.location.host ; 
	}
	
	if(queryParameters!=""){
		path=path+'?'+queryParameters;
	}else{
		path=path;
		
	}
	
	var baseurl='/ar/';
	
	
}else{
          document.body.dir = "ltr";
        sessionStorage.setItem('lang', 'en');
	var path = window.location.protocol + '//' + window.location.host + '/ar'+window.location.pathname; 
	if(queryParameters!=""){
		path=path+'?'+queryParameters;
	}else{
		path=path;
	}
	var baseurl='/';
}
    //alert(baseurl);

    //const url = window.location.protocol + '//' + window.location.host;
    //const url='http://localhost:3000/';

       useEffect(()=>{
    //getAllTopmenus();
    },[]);
    
    /*const getAllTopmenus=()=>{
    axios.get(`${url}past`).then((response)=>{
        const allTopmenus=response.data.topmenus.allTopmenus;
        getTopmenus(allTopmenus);
    }).catch(error=>console.error(`Error:${error}`));
    }*/
function changesLanguage(event) {
    //let loc = "http://localhost:3000/about/";
    const qurl = window.location.href;
    const surl = qurl.split("?");
    //alert(event.target.getAttribute("value"));
    sessionStorage.setItem('lang', event.target.getAttribute("value"));
    //window.location.replace(surl[0] + "?lng=" + event.target.value);
    window.location.replace(surl[0]);
    
    setLang(event.target.getAttribute("value"));
	document.body.dir = event.target.getAttribute("dir");
  }

  return (<>
{(() => {
        if(lngg[1]=="ar"){
            return (<><Helmet><html lang="ar" />
 <script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/259a3fa7cff7218dc7df2786/script.js"></script>
    <link href="/css/bootstrap.rtl.min.css" rel="stylesheet"></link><link href="/css/flexslider.css" rel="stylesheet"></link>
 <link href="/css/animation.css" rel="stylesheet"></link>
 <link href="/css/style_ar.css" rel="stylesheet"></link>
 <link href="/css/font-awesome.min.css" rel="stylesheet"></link>
 <link rel="stylesheet"  type="text/css"  charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"/>
<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"/>
<link href="https://code.jquery.com/ui/1.10.4/themes/ui-lightness/jquery-ui.css" rel="stylesheet"/>
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300;500;700&display=swap" rel="stylesheet"/>

</Helmet></>);
        }else{
            return (<><Helmet><html lang="en" />
 <script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/259a3fa7cff7218dc7df2786/script.js"></script>
<link href="/css/bootstrap.min.css" rel="stylesheet"></link><link href="/css/flexslider.css" rel="stylesheet"></link>
 <link href="/css/animation.css" rel="stylesheet"></link>
 <link href="/css/style.css" rel="stylesheet"></link>
 <link href="/css/font-awesome.min.css" rel="stylesheet"></link>
 <link rel="stylesheet"  type="text/css"  charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"/>
<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"/>
<link href="https://code.jquery.com/ui/1.10.4/themes/ui-lightness/jquery-ui.css" rel="stylesheet"/>
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300;500;700&display=swap" rel="stylesheet"/>

  </Helmet>
  </>); 
        }
    })()}
  
<header>
    <div className='container'>
      <div className="row">
        <div className="col-md-3">
          <a href={baseurl} className="only-pc">
            <img src="/images/alizz-logo.png" className="header-logo" alt="Alizz"/>
          </a>
			<a href={baseurl} className="only-mobile">
            <img src="/images/alizz-logo-mob.png" className="header-logo" alt="Alizz"/>
          </a>
          <nav className="navbar-light nvbar only-mobile">
		  {(() => {
        if(lngg[1]=="ar"){
	return (<> <a href={path} value="en" dir="ltr"  className="langlink" >English</a></>);}else{
	return (<><a href={path} value="ar" dir="rtl" className="langlink">عربي</a></>);
		}
	})()}
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </nav>
        </div>
        <div className="col-md-5">
          <div className="header-menu">
		  
			  
			  {(() => {
        if(lngg[1]=="ar"){
	return (<> <Topmenuar/></>);}else{
	return (<><Topmenu/></>);
		}
	})()}
            
          </div>

        </div>
        <div className="col-md-4">
          <div className="langdiv">
		  {(() => {
        if(lngg[1]=="ar"){
	return (<> <a href={path} value="en" dir="ltr"  className="langlink" >English</a></>);}else{
	return (<><a href={path} value="ar" dir="rtl" className="langlink" >عربي</a></>);
		}
	})()} 
	<a href="https://alizzconnect.com/" className="loginlink" target="_blank"><span className="lt1">
	{(() => {
        if(lngg[1]=="ar"){
				return (<><span>الخدمات المصرفية عبر الانترنت</span></>); 
        }else{
return (<><span>Internet Banking</span></>);
    }})()}
	 </span><span className="lt2">
	 {(() => {
        if(lngg[1]=="ar"){
				return (<><span>دخول</span></>); 
        }else{
return (<><span>Login</span></>);
    }})()}
	 </span></a>
            <a href="#" className="searchlink"><img src="/images/search.png" alt=""/></a>
			 <div className="srchbox" id="srchbox">
			 <form action="/search" method="get">
			 <input type="text" name="s" />
			 <button id="sbtn">Search</button>
			 </form>
			 </div>
          </div>
        </div>
      </div>
    </div>
  </header></>
  );
};
  
export default Header;