//Make the necessary imports
import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import parse from 'html-react-parser';
//import SlickSlider from "./Slider";
import Homefinanceformtabs from "../components/Homefinanceformtabs";

//GraphQL query to fetch all the blogs from the backend
export const SLIDESHOW = gql`
{
    homefinance {
      data {
        id
        attributes {
          localizations{
            data{
              attributes{
                Slider{
                  Title
                  Subtitle
                  Description
                  ButtonText
                  ButtonLink
                  Class
                  Cover{
                    data{
                      attributes{
                        name
                        url
                      }
                    }
                  }
                }
              }
            }
          }
            Slider{
              Title
              Subtitle
              Description
              ButtonText
              ButtonLink
              Class
              Cover{
               data{
                id
                attributes{
                  name
				  url
                  width
                height
                size
                formats
                createdAt
                previewUrl
                }
              }
              }
              }
              }
            }
          }
          }
`

/*const fscript = document.createElement("script");
    fscript.src = "/js/jquery.flexslider-min.js";
    fscript.async = true;
    document.body.appendChild(fscript);*/
	
export default function Accounts() {
document.title = "Home Finance";	
	
	var pth='https://alizzapi.alizzislamic.com';
	//var pth='http://localhost:8000/';
var settings = {
    dots: true,
	fade: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
	autoplay: true,
  autoplaySpeed: 4000,
  arrows: false
  };
//Execute the query using the useQuery hook and store the return values.
const { loading, error, data } = useQuery(SLIDESHOW);
//console.log(data);

if (loading) return <p>Loading...</p>

if (error) return <p>Error :(</p>
//const getLang = sessionStorage.getItem('lang');
//console.log(data.businessservice.data.attributes.Slider);
  return (
  <>
  <div className="bannerarea pb-4 pt-4">
	<div id="homebanner" className="flexsliders">
	<div className="container">
	  <div className="row">
			<div className="col-md-12 bnrcntbox hnm">
				
                  <h3>Home Finance</h3>
              </div>
			</div>
	  
	  
<Slider {...settings}>
      {
            data.homefinance.data.attributes.Slider.map((mslide,i) => (         
            <div  key={mslide+i} className="row">
              <div className="col-md-6">
                <div className="tbnr">
                <img src={pth + mslide.Cover.data.attributes.url} alt={mslide.Title} className="img-fluid"/>
				</div>
              </div>
              <div className="col-md-6 bnrcntbox">
                <div className="box">
                  <h5>{mslide.SubTitle}</h5>
                  <h3>{mslide.Title}</h3>
                  
				  {(() => {
        if(mslide.Description!="" && mslide.Description!=null){
	return (<><div className="bnrcnt">{parse(mslide.Description)}</div></>); 
        }
    })()}
				  {(() => {
        if(mslide.ButtonText!="" && mslide.ButtonText!=null){
	return (<> <a href={mslide.ButtonLink} className='btn btn-primary sty1 mx-2'>{mslide.ButtonText}</a></>); 
        }
    })()}
                </div>
              </div>
            </div>
          
		))
          }
    </Slider>
		
	</div>
	</div>
	</div>
	<div className="inrexpnddiv">
	<Homefinanceformtabs/>
	</div>
	

	  </>
  );
}