import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import Collapse from "@kunukn/react-collapse";
import Down from "./Down";
import parse from 'html-react-parser';

const initialState = [false, false, false];
function reducer(state, { type, index }) {
  switch (type) {
    case "expand-all":
      return [true, true, true];
    case "collapse-all":
      return [false, false, false];
    case "toggle":
      let newState = [...state];
      newState[index] = !newState[index];
      return newState;

    default:
      throw new Error("reducer configuration");
  }
}
function Block({ isOpen, title, onToggle, children }) {
  return (
    <div className="block">
      <button className="btn expn toggle" onClick={onToggle}>
        <span>{title}</span>
        <Down isOpen={isOpen} />
      </button>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </div>
  );
}
//GraphQL query to fetch all the blogs from the backend
const CAREERS = gql`
{
    careers {
      data {
        id       
        attributes {
          locale
          Title
          Content
          localizations{
            data{
              attributes{
                Title
                Content
              }
            }
          }
        }
      }
    }
  }
`

export default function Careers() {
document.title = 'الوظائف';
const [state, dispatch] = React.useReducer(reducer, initialState);
//Execute the query using the useQuery hook and store the return values.
const { loading, error, data } = useQuery(CAREERS)
//console.log(data);
//Display the following when fetching
if (loading) return <p>Loading...</p>
//Display the following in case an error is encountered
if (error) return <p>Error :(</p>
//log the data to the console
//const getLang = sessionStorage.getItem('lang');

  return (
  <>
 <div className="bannerarea pb-4 pt-4">
    <div id="innerbanner" className="bannerslider">

          <div className="container">
		
			<div className="row">
			<div className="col-md-12 bnrcntbox hnm">
                  <h3>الوظائف</h3>
              </div>
			</div>
			
          </div>

    </div>
	</div>
      <section class="section careers">
    <div class="container career-container">
      <div class="row">
	  

        <div class="col-md-12">
          {
            data.careers.data?.map(career => (
			<div>{(() => {
        if(career.attributes.localizations.data[0]){
	return (
                <>
                <div key={career.id} className='mt-2 mb-2 p-2 bg-white rounded-md'>
  
                    {/* Display only the first 150 characters of the body */}
                        {/*career.attributes.Content.substring(0,150)*/}

                    <Block
        title={career.attributes.localizations.data[0].attributes.Title}
        isOpen={state[career.id]}
        onToggle={() => dispatch({ type: "toggle", index: career.id })}
      >
        <div className="expcontent">
		  {(() => {
        if(career.attributes.localizations.data[0].attributes.Content!=null && career.attributes.localizations.data[0].attributes.Content!=""){
				return (<><div className="contdv">{parse(career.attributes.localizations.data[0].attributes.Content)}</div></>); 
        }
    })()}
		  <Link to={`mailto:recruitment@alizzislamic.com`} className='text-purple-600'>Apply Now</Link>
        </div>
      </Block>
                </div>
				</>
                );
}
})()}</div>
            ))
          }
      </div>
	  
	  
	  </div>
	  </div>
	  </section>
	  </>
  );
}