import React, { useState, useEffect  } from 'react';
import { graphql } from "@apollo/client/react/hoc";
import { InMemoryCache,ApolloClient ,useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
//import ReactCardSlider from 'react-card-slider-component';
import parse from 'html-react-parser';
import useForm from 'react-hook-form'
import $ from 'jquery';
import jQuery from 'jquery';
import Axios from 'axios';
window.$ = window.jQuery = require('jquery')

	/*const cscript = document.createElement("script");
    cscript.src = "/js/cardslider.js";
    cscript.async = true;
    document.body.appendChild(cscript);*/
	
class Businesstabs extends  React.Component  {
  constructor(props) {
    super(props);
    //this.getID = sessionStorage.getItem('pageid');
    //this.Lang = sessionStorage.getItem('lang');
//alert(this.Lang);
    //this.storageChanged = this.storageChanged.bind(this);

  }
state = {
        sliderdet: [],
      }

      componentDidMount() {
	//var lng= sessionStorage.getItem('lang');
	Axios.get("https://alizzapi.alizzislamic.com/api/business?locale=ar&populate[0]=BusinessBox.BusinessDetails.Icon")
        //Axios.get('./menu.json')
          .then(res => {
            const sliderdet = res.data.data.attributes.BusinessBox;
			this.setState({ sliderdet });
            //console.log(sliderdet);
          });
//alert(4);

    	setTimeout(
    function() {

	
    }
    .bind(this),
    1500
);  

      }
 handleClickEvent(e, value) {
       // var clck = this.state.context.find('#dd'+value);
		//console.log("clicked id:"+clck);
    } 
      render() {
    //console.log(this.props);
	var asr=[];
	asr=this.state.sliderdet;
	
	//console.log(this.state.sliderdet);
	//alert(asr);

	var pth='https://alizzapi.alizzislamic.com/';
	var actnm='tb';
	var ub="ub";
	var mb="mb";
    return (
	<>
<section className="section whatyousect">
    <div className="container pt-3 pb-5">
      <div className="row">
        <div className="col-md-12 text-center mb-2">
          {/*<h3>What you like to do today</h3>*/}
        </div>
      </div>

	  
	  
	  {asr.map((post, index) => {
	//console.log(post.AccountExtra);
   return (
    <>
      <div key={post.id} className={`justify-content-center contfdiv ${index==1 ? "active" : ""}`} id={actnm+post.id}>
        <div className="row boxoutersty">
		{post.BusinessDetails.map((ipost, iindex) => {
          return (
    <>
	<div className={`col-md-4 btbs ${iindex==0 ? "bact" : ""}`} fl={ub+iindex+post.id} mn={mb+index+post.id} bx={actnm+post.id}>
            <div className="d-flex justify-content-center">
              <span className="rounded-circle">
				<img src={pth + ipost.Icon.data.attributes.url} alt={ipost.Title} className="img-fluid imglogo"/>

              </span>
            </div>
            <span className="rounded-nw border rnddiv">
              <div className="text-center">
                <h3 className="hd-ty1">{ipost.Title}</h3>
              </div>
				  {(() => {
			  if(ipost.Content!=null){
				return (<><div className="fcnt">{parse(ipost.Content)}</div></>); 
        }
		 })()}	
              <div className="text-center btndiv">
                <a href={ipost.Link} className="linkh btn btn-primary">
                  {ipost.LinkText}
                </a>
              </div>
            </span>

          </div>
		  </>
   )
})}



        </div>
      </div>
	  </>
   )
})}
	  
	  
	  
	</div>
</section>

{asr?.map((epost, index) => {
	//console.log(post.AccountExtra);
   return (
    <>
 <div className={`tabcntdiv ${index==1 ? "active" : ""}`} id={mb+index+epost.id}>
	  
		{epost.BusinessDetails?.map((eipost, iindex) => {
			//console.log(ipost);
         return (
    <>
	{(() => {
		if(eipost.TabContent!=null){
			return (<><section className={`tab-inner ${iindex==0 ? "act" : ""}`} id={ub+iindex+epost.id}>
        <div className="container mt-3">
		<div class="tnpnlinr mt-3">
			<div class="aoaccordion" id="accordion00">
			{parse(eipost.TabContent)}
			</div>
			</div>
			</div>
		</section>
			</>);
		}
    })()}

		  </>
   )
})}
		
	  
	</div>  
	  </>
   )
})}


</>)
         
  }
    }


export default Businesstabs;
    