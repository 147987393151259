import React, { useState, useEffect } from 'react';
import { MenuComponent, } from '@syncfusion/ej2-react-navigations';
import { Link } from 'react-router-dom';
import { ShimmerPostItem  } from "react-shimmer-effects";
import Axios from 'axios';
const menuFields = {
    itemId: 'd',
    parentId: 'pid',
    text: 'text'
};
export default class MenuList extends React.Component {
    constructor(props) {
        super(props);
        //this.getID = sessionStorage.getItem('pageid');
        //this.Lang = sessionStorage.getItem('lang');
        //this.storageChanged = this.storageChanged.bind(this);
    }
    state = {
        //mainmenus: [{"pageid":"2","pid":"null","text":"Home","slug":"home","url":"http:\/\/localhost:3000\/home\/"},{"pageid":"6","child":[{"pageid":"13","pid":"6","text":"Chairman","slug":"chairman","url":"http:\/\/localhost:3000\/about\/chairman\/"},{"pageid":"11","pid":"6","text":"Vision","slug":"vision","url":"http:\/\/localhost:3000\/about\/vision\/"}],"pid":"null","text":"About","slug":"about","url":"http:\/\/localhost:3000\/about\/"},{"pageid":"9","pid":"null","text":"Contact","slug":"contact","url":"http:\/\/localhost:3000\/contact\/"}]
        mainmenus: []
    }
    componentDidMount() {
        //Axios.get(`https://alizzapi.alizzislamic.com/api/navigation/render/main-menu?type=TREE&locale=${this.Lang}`)
		Axios.get(`https://alizzapi.alizzislamic.com/api/navigation/render/main-menu?type=TREE&locale=en`)
            //Axios.get('./menu.json')
            .then(res => {
                const mainmenus = res.data;
                this.setState({ mainmenus });

            })

    }
    handleChangeDash(event) {
        var attribute = event.target.attributes.getNamedItem('data-tag').value;
        //alert(attribute);
        sessionStorage.setItem('pageid', attribute);
    }
    onLinkClick(e) {
        //e.preventDefault();
        // further processing happens here
        var attribute = e.target.attributes.getNamedItem('data-tag').value;
        sessionStorage.setItem('pageid', attribute);
        //console.log(attribute);
        //window.location.reload();
    }
    render() {
        //console.log(this.state.mainmenus);
        var mgg = "mmnu";
        var gg = "smnu";
		const { loading, error, data } = this.state.mainmenus;
		if (loading){ return <div className="container"><ShimmerPostItem card title text cta /></div>}
        return (

            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <div className="collapse navbar-collapse" id="navbarNav"><ul className="navbar-nav mainmenu">{
                        this.state.mainmenus
                            .map((mainmenu, index) => {
                                return (
                                    <li key={mainmenu.id} className="nav-item"><a id={mgg+mainmenu.id} onClick={this.onLinkClick} className="nav-link" data-tag={mainmenu.id} href={mainmenu.path}>{mainmenu.title}</a>{/*<Link to={mainmenu.path} className="nav-link" data-tag={mainmenu.id} onClick={this.onLinkClick} id={mgg+mainmenu.id} >{mainmenu.title}</Link>*/}{(() => {
                                        if (mainmenu.items[0]) {
                                            return (<><div className="submenudiv">
                                                <div className="container"><ul className="submenuf">{
                                                    mainmenu.items
                                                        .map((submenu, index) => {
                                                            return (<li key={submenu.id} className="tplevel2" id={gg + submenu.id}><a  onClick={this.onLinkClick} className="secondLevel" data-tag={submenu.id} href={submenu.path}>{submenu.title}</a>
                                                                {/*<Link to={submenu.path} className="secondLevel" data-tag={submenu.id} onClick={this.onLinkClick} >{submenu.title}</Link>*/}
                                                                {(() => {
                                                                    //console.log(submenu);
                                                                    if (submenu.items[0]) {
                                                                        return (<div className="submenuscndiv">
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <ul className="submenus">{
                                                                                        submenu.items
                                                                                            .map((ssubmenu, index) => {
                                                                                                return (<li key={ssubmenu.id}><a onClick={this.onLinkClick} href={ssubmenu.path} data-tag={ssubmenu.id}>{/*<img src="images/auto-finance.svg">*/}
                                                                                                    <div className="mnutxt">
                                                                                                        <h4>{ssubmenu.title}</h4>
                                                                                                        <p>{ssubmenu.menucontent}</p>
                                                                                                    </div>
                                                                                                </a> </li>)
                                                                                            })}
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>)
                                                                    }
                                                                })()}
                                                            </li>)
                                                        }
                                                        )
                                                }</ul></div></div></>)
                                        }
                                    })()}</li>
                                )
                            }
                            )
                    }</ul>
                    </div>
                </div></nav>)
    }
}



