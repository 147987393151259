import React, { useState, useEffect } from "react";
import './App.css';
import $ from 'jquery';
import jQuery from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { ReactComponent as Logo } from './alizz-logo.png';

//import 'flexslider';
import Header from "./components/Header";
import Homepage from "./pages/Homepage";
import BlogDetails from "./pages/BlogDetails";
import PersonalDetails from "./pages/PersonalDetails";
import Accounts from "./pages/Accounts";
import Finance from "./pages/Finance";
import Takaful from "./pages/Takaful";
import Page from "./pages/Page";
import Careers from "./pages/Careers";
import CareerForm from "./pages/CareerForm";
import Careerpage from "./pages/Careerpage";
import Branchlocations from "./pages/Branchlocations";
import Customerexperience from "./pages/Customerexperience";
import Footer from "./components/Footer";
import Financials from "./pages/Financials";
import Pressrelease from "./pages/Pressrelease";
import Creditcard from "./pages/Creditcard";
import Business from "./pages/Business";
import Digital from "./pages/Digital";
import BOD from "./pages/BOD";
import RegulatoryDisclosure from "./pages/RegulatoryDisclosure";
import Termsconditions from "./pages/Termsconditions";
import Historicalprofitrates from "./pages/Historicalprofitrates";
import Tharwa from "./pages/Tharwa";
import Keyfactstatements from "./pages/Keyfactstatements";
import Shariafatwacertificates from "./pages/Shariafatwacertificates";
import Success from "./pages/Success";
import Businessservices from "./pages/Businessservices";
import Alizzjunior from "./pages/Alizzjunior";
import Alizzone from "./pages/Alizzone";
import Homefinance from "./pages/Homefinance";
import Autofinance from "./pages/Autofinance";
import Iban from "./pages/Iban";
import Iban2 from "./pages/Iban2";
import Businessaccounts from "./pages/Businessaccounts";
import Businessfinance from "./pages/Businessfinance";
import Businessservice from "./pages/Businessservice";

import Homepagear from "./pages/Homepagear";
import BlogDetailsar from "./pages/BlogDetailsar";
import PersonalDetailsar from "./pages/PersonalDetailsar";
import Accountsar from "./pages/Accountsar";
import Financear from "./pages/Financear";
import Takafular from "./pages/Takafular";
import Pagear from "./pages/Pagear";
import Careersar from "./pages/Careersar";
import CareerFormar from "./pages/CareerFormar";
import Careerpagear from "./pages/Careerpagear";
import Branchlocationsar from "./pages/Branchlocationsar";
import Customerexperiencear from "./pages/Customerexperiencear";
import Footerar from "./components/Footer";
import Financialsar from "./pages/Financialsar";
import Pressreleasear from "./pages/Pressreleasear";
import Creditcardar from "./pages/Creditcardar";
import Businessar from "./pages/Businessar";
import Digitalar from "./pages/Digitalar";
import BODar from "./pages/BODar";
import RegulatoryDisclosurear from "./pages/RegulatoryDisclosurear";
import Termsconditionsar from "./pages/Termsconditionsar";
import Historicalprofitratesar from "./pages/Historicalprofitratesar";
import Tharwaar from "./pages/Tharwaar";
import Keyfactstatementsar from "./pages/Keyfactstatementsar";
import Shariafatwacertificatesar from "./pages/Shariafatwacertificatesar";
import Successar from "./pages/Successar";
import Businessservicesar from "./pages/Businessservicesar";
import Alizzjuniorar from "./pages/Alizzjuniorar";
import Alizzonear from "./pages/Alizzonear";
import Homefinancear from "./pages/Homefinancear";
import Autofinancear from "./pages/Autofinancear";
import Ibanar from "./pages/Ibanar";
import Iban2ar from "./pages/Iban2ar";
import Businessaccountsar from "./pages/Businessaccountsar";
import Businessfinancear from "./pages/Businessfinancear";
import Businessservicear from "./pages/Businessservicear";
import Axios from 'axios';
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client"

import {
  BrowserRouter,
  Routes,
  Route,
  useParams
} from "react-router-dom";

import {Helmet} from "react-helmet";

// initialize apollo client
const client = new ApolloClient({
  uri: 'https://alizzapi.alizzislamic.com/graphql',
  //uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
	//uri: 'http://localhost:1337/graphql',
  cache: new InMemoryCache()
})


function App() {
	const [items, setItems,lang, setLang] = useState([]);
    //const getLang = sessionStorage.getItem('lang');
//console.log(getLang);
  return (
    <BrowserRouter>
	<Helmet>
	</Helmet>
      <ApolloProvider client={client}>
            <Header />
            <Routes>
                 
			  <Route path="/:slug" element={<Page />} />
			  <Route path="/:slug/:slug" element={<Page />} />
			  <Route path="/:slug/:slug/:slug" element={<Page />} />
			  <Route path="/ar/:slug" element={<Pagear />} />
			  <Route path="/ar/:slug/:slug" element={<Pagear />} />
			  <Route path="/ar/:slug/:slug/:slug" element={<Pagear />} />
	  
				<Route path="/" element={<Homepage />} />
			  <Route path="/personal" element={<PersonalDetails />} />
			  <Route path="/personal/accounts" element={<Accounts />} />
			  <Route path="/personal/finance" element={<Finance />} />
			  <Route path="/personal/takaful" element={<Takaful />} />
			  <Route path="/the-bank/contact-us/branch-locations" element={<Branchlocations />} />
			  <Route path="/job-openings" element={<Careers />} />
			  <Route path="/applynow/:id" element={<CareerForm />} />
			  <Route path="the-bank/contact-us/careers" element={<Careerpage />} />
			  <Route path="/the-bank/contact-us/customer-experience-inquiry" element={<Customerexperience />} />
			  <Route path="/the-bank/aboutus/financials" element={<Financials />} />
			  <Route path="/the-bank/media/press-releases" element={<Pressrelease />} />
				  <Route path="/the-bank/media/press-releases/:id" element={<BlogDetails />} />
				  <Route path="/personal/creditcard" element={<Creditcard />} />
				  <Route path="/business" element={<Business />} />
				  <Route path="/personal/digital" element={<Digital />} />
				  <Route path="/the-bank/aboutus/bod" element={<BOD />} />
				  <Route path="/the-bank/aboutus/regulatory-disclosure" element={<RegulatoryDisclosure />} />
				  <Route path="/personal/others/terms-conditions" element={<Termsconditions />} />
				  <Route path="/personal/others/historical-profit-rates" element={<Historicalprofitrates />} />
				  <Route path="/personal/tharwa" element={<Tharwa />} />
				  <Route path="/the-bank/contact-us/key-fact-statements" element={<Keyfactstatements />} />
				  <Route path="/the-bank/knowledge-centre/sharia-fatwa-certificates" element={<Shariafatwacertificates />} />
				  <Route path="/success" element={<Success />} />
				  <Route path="/business/alizz-business" element={<Businessservices />} />
				<Route path="/alizz-junior" element={<Alizzjunior />} />
				<Route path="/alizz-one" element={<Alizzone />} />
				<Route path="/home-finance" element={<Homefinance />} />
				<Route path="/auto-finance" element={<Autofinance />} />
				<Route path="/personal/iban" element={<Iban />} />
				<Route path="/iban2" element={<Iban2 />} />
	  			<Route path="/business/accounts" element={<Businessaccounts />} />
				<Route path="/business/finance" element={<Businessfinance />} />
				<Route path="/business/services" element={<Businessservice />} />
	  
	           <Route path="/ar" element={<Homepagear />} />
	            <Route path="/ar/personal" element={<PersonalDetailsar />} />
			  <Route path="/ar/personal/accounts" element={<Accountsar />} />
			  <Route path="/ar/personal/finance" element={<Financear />} />
			  <Route path="/ar/personal/takaful" element={<Takafular />} />
			  <Route path="/ar/the-bank/contact-us/branch-locations" element={<Branchlocationsar />} />
			  <Route path="/ar/job-openings" element={<Careersar />} />
			  <Route path="/ar/applynow/:id" element={<CareerFormar />} />
			  <Route path="/ar/the-bank/contact-us/careers" element={<Careerpagear />} />
			  <Route path="/ar/the-bank/contact-us/customer-experience-inquiry" element={<Customerexperiencear />} />
			  <Route path="/ar/the-bank/aboutus/financials" element={<Financialsar />} />
			  <Route path="/ar/the-bank/media/press-releases" element={<Pressreleasear />} />
				  <Route path="/ar/the-bank/media/press-releases/:id" element={<BlogDetailsar />} />
				  <Route path="/ar/personal/creditcard" element={<Creditcardar />} />
				  <Route path="/ar/business" element={<Businessar />} />
				  <Route path="/ar/personal/digital" element={<Digitalar />} />
				  <Route path="/ar/the-bank/aboutus/bod" element={<BODar />} />
				  <Route path="/ar/the-bank/aboutus/regulatory-disclosure" element={<RegulatoryDisclosurear />} />
				  <Route path="/ar/personal/others/terms-conditions" element={<Termsconditionsar />} />
				  <Route path="/ar/personal/others/historical-profit-rates" element={<Historicalprofitratesar />} />
				  <Route path="/ar/personal/tharwa" element={<Tharwaar />} />
				  <Route path="/ar/the-bank/contact-us/key-fact-statements" element={<Keyfactstatementsar />} />
				  <Route path="/ar/the-bank/knowledge-centre/sharia-fatwa-certificates" element={<Shariafatwacertificatesar />} />
				  <Route path="/ar/success" element={<Successar />} />
				  <Route path="/ar/business/alizz-business" element={<Businessservicesar />} />
				<Route path="/ar/alizz-junior" element={<Alizzjuniorar />} />
				<Route path="/ar/alizz-one" element={<Alizzonear />} />
				<Route path="/ar/home-finance" element={<Homefinancear />} />
				<Route path="/ar/auto-finance" element={<Autofinancear />} />
				<Route path="/ar/personal/iban" element={<Ibanar />} />
				<Route path="/ar/iban2" element={<Iban2ar />} />
	  			<Route path="/ar/business/accounts" element={<Businessaccountsar />} />
				<Route path="/ar/business/finance" element={<Businessfinancear />} />
				<Route path="/ar/business/services" element={<Businessservicear />} />
            </Routes>
			<Footer />
      </ApolloProvider>
    </BrowserRouter>
  );
}

export default App;